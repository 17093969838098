import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Tag, Modal, PageHeader, Table, message, Alert, Button, Space, Menu, Tooltip, Typography, Select, Dropdown } from 'antd';

import { EyeOutlined, CalendarOutlined, GlobalOutlined, SyncOutlined, SearchOutlined , CloudDownloadOutlined } from "@ant-design/icons"

import locale from 'antd/es/date-picker/locale/fr_FR'
import moment from 'moment'

import ListeNationalite from '../Data/ListeNationalite'
import UserContext from "../../Context/User"
import { getPersonFrom, downloadList } from '../../API/List'
import { createAndDownloadBlobFile } from '../../API/Util'

import hasEnoughRight, { ROLE } from "../../Utils/hasEnoughRight"
import throttle from "../../Utils/Throttle"
import Title from "../../Utils/Title"


import PersonInformation from "../Client/PersonInformation"

import { filterSearch, filterDatePicker, filterNationality } from "./Filter"
import MaxScore from "./MaxScore"

const { Column } = Table;

const FORMAT_DATE = "DD/MM/YYYY"

const DEFAULT_FILTER_STATE = {
    raisonsociale: "",
    nom: "",
    nomnaissance: "",
    prenom: "",
    datenaissance: "",
    nationalite: ""
}

const InitialListState = {
    liste: null,
    loading: true,
    search: false,
    searchComplete: true,
    typeclient: "PHYSIQUE",
    CA_show: "NEED_CHECK",
    filtre_liste_controle: ["datalake-gel", "datalake-ppe", "rnipp"],
    MODAL_search_content_visible: false,
    data: [],
    ...DEFAULT_FILTER_STATE
}

export default class List extends Component {
    static contextType = UserContext

    state = InitialListState

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({ ...InitialListState, search: newProps.location.pathname === "/recherche", liste: newProps.match.params.list }, this.getResult)
    }

    componentDidMount() {
        if (this.props.location.pathname === "/recherche") {
            this.setState({ search: true, ...this.props.location.state }, this.getResult)
        } else {
            this.setState({ liste: this.props.match.params.list }, this.getResult)
        }

        window.onscroll = throttle(_ => {
            // On peut scroll, si il ne s'agit pas de résultat d'une recherche, ou si il y a plusieurs liste de sélectionné (this.state.liste == array)
            if (document.body.scrollHeight - document.body.clientHeight - window.scrollY < 1000) {
                this.state.liste !== "controle-auditeur" && typeof this.state.liste === "string" && this.getResult(true)
            }
        }, 500)
    }

    getResult = async (more = false, reset = false) => {
        let { liste } = this.state

        this.setState({ loading: true })

        var params = {
            typeclient: this.state.typeclient,
            raisonsociale: this.state.raisonsociale,
            nom: this.state.nom,
            nomnaissance: this.state.nomnaissance,
            prenom: this.state.prenom,
            datenaissance: this.state.datenaissance,
            nationalite: Array.isArray(this.state.nationalite) && this.state.nationalite.map(e => e.replace("n-", ""))
        }

        if (more === true) {
            params.after = this.state.data[this.state.data.length - 1]["sort"]
        }

        if (liste === "controle-auditeur") {
            params.controle = this.state.CA_show
            liste = "client"
            params.filtre_liste_controle = this.state.filtre_liste_controle
        }

        if (reset === true) {
            getPersonFrom.clear_cache(liste, params, this.context.user.token)
            console.log("Clear cache")
        }

        let res = await getPersonFrom(liste, params, this.context.user.token)

        let result = more === true ? [...this.state.data, ...res] : [...res]

        const maxScorePPE = Math.max(...result.filter(e => e._index.includes("ppe")).map(e => e._score), 0)
        const maxScoreGEL = Math.max(...result.filter(e => e._index.includes("gel")).map(e => e._score), 0)

        this.setState({ loading: false, data: result, maxScorePPE, maxScoreGEL })
    }

    goBack = _ => {
        this.props.history.goBack()
        this.props.history.replace("/", this.props.location.state)
    }

    downloadFile = format => {
        let { liste } = this.state

        var params = {
            typeclient: this.state.typeclient,
            raisonsociale: this.state.raisonsociale,
            nom: this.state.nom,
            nomnaissance: this.state.nomnaissance,
            prenom: this.state.prenom,
            datenaissance: this.state.datenaissance,
            nationalite: this.state.nationalite,
            size: "all"
        }

        if (liste === "controle-auditeur") {
            params.controle = this.state.CA_show
            liste = "client"
            params.filtre_liste_controle = this.state.filtre_liste_controle
        }


        if(params.nationalite) params.nationalite = params.nationalite.map(e => e.replace("n-", ""))

        downloadList(liste, params, format, this.context.user.token).then(data => {
            createAndDownloadBlobFile(data, `export`, format)
        }).catch(err => {
            console.error(err)
            message.error("Erreur lors de l'extraction de la table")
        })
    }

    onTableFilterChange = (_, filters) => {
        
        const searchFilter = {
            nom: (filters["_source.NOM"] || [])[0],
            raisonsociale: (filters["_source.RAISONSOCIALE"] || [])[0],
            prenom: (filters["_source.PRENOM"] || [])[0],
            nomnaissance: (filters["_source.NOMNAISSANCE"] || [])[0],
            datenaissance: (filters["_source.DATENAISSANCE"] || [])[0],
            nationalite: filters["_source.NATIONALITE"],
        }

        this.setState({ ...searchFilter }, this.getResult)
    }

    // Ouvrir / Fermer la modal
    showSearchContent = _ => this.setState({MODAL_search_content_visible: true})
    closeSearchContent = _ => this.setState({MODAL_search_content_visible: false})

    render() {
        
        // Génération du titre de la page
        let titre;
        if (this.state.search) {
            titre = "Résultats de recherche"
        } else {
            titre = (this.state.liste || "").toUpperCase().replace("DATALAKE-", "")
            if (titre === "CLIENT") { titre = "Référentiel Client" }
            else if (titre === "CONTROLE-AUDITEUR") { titre = "Contrôle auditeur" }
            else { titre = "Liste " + titre }
        }


        // Génération des infos pour la modal d'affichage du contenu de la recherche
        var SEARCH_CONTENT = clean({
            TYPECLIENT: this.state.typeclient,
            RAISONSOCIALE: Title(this.state.raisonsociale),
            NOM: Title(this.state.nom),
            NOMNAISSANCE: Title(this.state.nomnaissance),
            PRENOM: Title(this.state.prenom),
            DATENAISSANCE: this.state.datenaissance
        })
        if(this.state.nationalite) SEARCH_CONTENT.NATIONALITE = this.state.nationalite.map(e => e.replace("n-", ""))


        /// Utilisé pour récupérer le role de l'user actuel
        const { user } = this.context

        return (
            <PageHeader
                title={titre}
                subTitle={
                    this.state.search && !this.state.loading && <Tooltip title="Contenu de la recherche">
                        <Button onClick={this.showSearchContent} icon={<EyeOutlined />} />
                    </Tooltip>
                }
                onBack={this.state.search && this.goBack}
                ghost={false}
                key={100000}
                extra={[
                    // Bouton "controle auditeur"
                    !this.state.search && this.state.liste === "client" && hasEnoughRight(user.role, ROLE.Auditeur) && <Link key={1} to="/list/controle-auditeur"><Button type="primary">Contrôle auditeur</Button></Link>,
                    
                    // Select "Comparaison à vérifier / Comparaison confirmée"
                    this.state.liste === "controle-auditeur" && <>
                        Affichage de :
                        <Select
                            key={2}
                            style={{ marginLeft: 15, width: 225 }}
                            defaultValue={this.state.CA_show}
                            onSelect={async CA_show => this.setState({ CA_show, ...DEFAULT_FILTER_STATE }, this.getResult)}>
                            <Select.Option value="NEED_CHECK">Comparaison à vérifier</Select.Option>
                            <Select.Option value="APPROVED">Comparaison confirmée</Select.Option>
                            <Select.Option value="REMOVED">Comparaison non confirmée</Select.Option>
                        </Select>
                    </>,

                    // Select "Type de client"
                    !this.state.search && <Select
                        key={3}
                        style={{ marginLeft: 15, width: 175 }}
                        value={this.state.typeclient}
                        onSelect={async typeclient => this.setState({ typeclient, ...DEFAULT_FILTER_STATE }, this.getResult)}>
                        <Select.Option key={9812312} value="PHYSIQUE">Personne Physique</Select.Option>
                        <Select.Option key={98123} value="MORALE">Personne Morale</Select.Option>
                    </Select>,

                    
                    // Select RNIPP, GEL, PPE pour controle auditeur uniquement
                    this.state.liste === "controle-auditeur" && <Select
                        key={4}
                        mode="multiple"
                        style={{ marginLeft: 15, maxWidth: 310, minWidth: 175 }}
                        value={this.state.filtre_liste_controle}
                        onChange={async filtre_liste_controle => this.setState({ filtre_liste_controle }, this.getResult)}>
                            <Select.Option key={41} value="datalake-gel">Liste GEL</Select.Option>
                            <Select.Option key={42} value="datalake-ppe">Liste PPE</Select.Option>
                            <Select.Option key={43} value="rnipp">Liste RNIPP</Select.Option>
                    </Select>,

                    // Bouton "Actualiser"
                    this.state.liste === "controle-auditeur" && <Tooltip title="Actualiser" key={5}>
                        <Button key={2} onClick={_ => this.getResult(false, true)} icon={<SyncOutlined />}></Button>
                    </Tooltip>,
                    
                    // Bouton/Dropdown "Télécharger"
                    <Tooltip title="Télécharger" key={6} >
                        <Dropdown
                            key={61}
                            overlay={
                                <Menu key={612} onClick={_ => null}>
                                    <Menu.Item key={613} onClick={_ => this.downloadFile("xlsx")}>.XLXS</Menu.Item>
                                    <Menu.Item key={614} onClick={_ => this.downloadFile("csv")}>.CSV</Menu.Item>
                                </Menu>
                            }>
                            <Button key={615} icon={<CloudDownloadOutlined key={6151} />} />
                        </Dropdown>
                    </Tooltip>
                ]}>

                {/* MODAL : Info recherche */}
                <Modal
                    title="Score et contenu de la recherche"
                    footer={null}
                    onCancel={this.closeSearchContent}
                    visible={this.state.MODAL_search_content_visible}>

                        <Space size="large" align="center" direction="vertical" style={{width: "100%"}}>
                            {this.state.search && !this.state.loading && !this.state.liste.includes("client") && <MaxScore maxScoreGEL={this.state.maxScoreGEL} maxScorePPE={this.state.maxScorePPE} /> }
                          
                            <PersonInformation
                                person={SEARCH_CONTENT}
                                showTypeClient={true} />

                            <Tag color="blue">Recherche effectuée le : { (new Date()).toLocaleString() }</Tag>
                        </Space>

                </Modal>
                {/* MODAL : Info recherche */}



                <Space direction="vertical" style={{ width: "100%" }}>

                    {/* SCORE DE RECHERCHE */}
                    {this.state.search && !this.state.loading && !this.state.liste.includes("client") && <>
                            <Typography.Title style={{ textAlign: "center" }} level={4}>Score maximum</Typography.Title>
                            <MaxScore maxScoreGEL={this.state.maxScoreGEL} maxScorePPE={this.state.maxScorePPE} />
                            <br />
                        </>}
                    {/* SCORE DE RECHERCHE */}

                    {/* MESSAGE D'ERREUR SI RECHERCHE INCOMPLETE */}
                    {!this.state.loading && this.state.search && <SearchWarning state={this.state} />}
                    {/* MESSAGE D'ERREUR SI RECHERCHE INCOMPLETE */}

                    <Table
                        dataSource={this.state.data.filter(d => d._source.TYPECLIENT === this.state.typeclient)}
                        loading={this.state.loading}
                        pagination={false}
                        rowKey={r => r.sort || r._id}
                        onChange={this.onTableFilterChange}
                        expandable={ this.state.liste === "datalake-ppe" && {
                            expandedRowRender: ({_source: {QUALITE: val}}) => <p style={{ margin: 0 }}>Qualite : {Array.isArray(val) ? val.map(e => <Tag key={e}>{e}</Tag>) : <Tag key={val}>{val}</Tag>}</p>,
                            rowExpandable: ({_source}) => !!_source.QUALITE,
                        }}
                        locale={locale}>

                        {
                            this.state.typeclient === "PHYSIQUE" ?

                                <>
                                    <Column
                                        title="Nom"
                                        dataIndex={["_source", "NOM"]}
                                        filteredValue={this.state.nom && [this.state.nom]}
                                        filterIcon={filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />}
                                        filterDropdown={filterSearch}
                                    />

                                    {this.state.liste === "client" && <Column
                                        title="Nom de Naissance"
                                        dataIndex={["_source", "NOMNAISSANCE"]}
                                        filteredValue={this.state.nomnaissance && [this.state.nomnaissance]}
                                        filterIcon={filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />}
                                        filterDropdown={filterSearch}
                                    />}
                                    
                                    <Column
                                        title="Prenom"
                                        dataIndex={["_source", "PRENOM"]}
                                        filteredValue={this.state.prenom && [this.state.prenom]}
                                        render={v => Array.isArray(v) ? v.map(e => Title(e)).join(", ") : Title(v)}
                                        filterIcon={filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />}
                                        filterDropdown={filterSearch}
                                    />

                                    <Column
                                        title="Date de naissance"
                                        dataIndex={["_source", "DATENAISSANCE"]}
                                        align="center"

                                        filteredValue={this.state.datenaissance && [this.state.datenaissance]}
                                        filterIcon={filtered => <CalendarOutlined style={{ color: filtered ? '#1890ff' : undefined }} />}
                                        filterDropdown={filterDatePicker}
                                        render={value => value ? (moment(value)).format(FORMAT_DATE) : ""} />
                    

                                    {/*
                                        this.state.liste === "datalake-ppe" &&
                                            <Column
                                                title="Qualité"
                                                dataIndex={["_source", "QUALITE"]}
                                                render={val => } />
                                    */}

                                    {
                                        this.state.liste !== "datalake-ppe" &&
                                        <Column
                                            title="Nationalité"
                                            dataIndex={["_source", "NATIONALITE"]}

                                            filteredValue={this.state.nationalite}
                                            filterIcon={filtered => <GlobalOutlined style={{ color: filtered ? '#1890ff' : undefined }} />}
                                            filterDropdown={filterNationality}

                                            render={(val, d) => {
                                                if (val) {
                                                    if (Array.isArray(val)) {
                                                        val = val.map(val => {
                                                            try {
                                                                val = parseInt(val)
                                                            } catch (e) { }

                                                            return ListeNationalite.find(e => e.id === val)
                                                        }).filter(e => e !== undefined).map(e => e.name)
                                                    } else {
                                                        try {
                                                            val = ListeNationalite.find(e => e.id === parseInt(val))
                                                            val = [val !== undefined ? val.name : "Code pays non trouvé"]
                                                        } catch (e) {
                                                            val = ["Code pays non trouvé"]
                                                        }
                                                    }

                                                    return val.map(e => <Tag key={e + d.sort}>{e}</Tag>)
                                                } else return ""
                                            }} />
                                    }

                                    {
                                        this.state.liste !== "client" && <>
                                            {
                                                this.state.search && <Column
                                                    title="Liste"
                                                    dataIndex="_index"
                                                    render={e => {
                                                        const v = e.replace("datalake-", "").toUpperCase()
                                                        return <Tag key={e}>{v.includes("CLIENT") ? "Client" : v}</Tag>
                                                    }} />
                                            }

                                            {
                                                this.state.liste === "controle-auditeur" && <Column
                                                    title="Score"
                                                    dataIndex={["_source", "COMPARAISON"]}
                                                    render={e => Array.isArray(e) ? e.map(d => <Tag key={Math.random()}>{d.INDEX_MATCH.replace("datalake-", "").toUpperCase()} : {d.SCORE}</Tag>) : <Tag>{JSON.stringify(e)}</Tag>} />
                                            }

                                            {
                                                this.state.search && <Column
                                                    title={<>Score de<br />recherche</>}
                                                    dataIndex={"scoring"}
                                                    render={(e, i) => {
                                                        const color = e > 75 ? (e > 80 ? "red" : "orange") : e === 75 && "gold"
                                                        return <Tag color={color}>{e}</Tag>
                                                    }
                                                    } />
                                            }
                                        </>
                                    }
                                </>
                                :
                                <Column
                                    title="Raison Sociale"
                                    dataIndex={["_source", "RAISONSOCIALE"]}
                                    filteredValue={this.state.raisonsociale && [this.state.raisonsociale]}
                                    filterIcon={filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />}
                                    filterDropdown={filterSearch}
                                />
                        }

                        {
                            ((this.state.liste || "").includes("client") || this.state.liste === "controle-auditeur" || this.state.search) && hasEnoughRight(user.role, ROLE.Auditeur) &&
                            <Column
                                fixed="right"
                                width={100}
                                title="Action"
                                align="center"
                                render={(text, record) => {

                                    return (record._index.includes("client") || this.state.search && record.COMPARAISON) && <Link to={"/client/" + record["_id"]}><Button type="primary">Voir</Button></Link>
                                }}
                            />
                        }
                    </Table>

                </Space>
            </PageHeader>
        )
    }
}

function SearchWarning({ state }) {
    let champ = ["nom", "prenom", "datenaissance"]
    let champ_texte = ["nom", "prenom", "date de naissance"]

    if (state.typeclient && state.typeclient === 'MORALE') {
        champ = ["raisonsociale"];
        champ_texte = ["Raison sociale"];
    }
    
    // Récupération des valeurs du state parent + conversion des valeurs en bool
    const champ_bool = champ.map(e => state[e]).map(e => !!e)

    // Recherche d'un false dans le tableau
    const warningState = champ_bool.includes(false)

    // On récupère les valeurs de champ_texte qui ne sont marqué comme false dans champ_bool et on join 
    const champs_manquants = champ_texte.filter((_, i) => !champ_bool[i]).join(", ")

    // On affiche si il manque un champ
    return warningState && <Alert
        message="Attention"
        description={<>Vous n'avez pas saisi de : {champs_manquants}.<br/>Veuillez noter que les scores et les résultats peuvent être imprécis.</>}
        type="warning"
        showIcon
    />
}

// Permet d'enlever les éléments vides d'un objet
function clean(obj) {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
            delete obj[propName];
        }
    }
    return obj
}
