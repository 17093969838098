import React from 'react';

import { Modal, List, message, Popconfirm, Form, Card, Button, Input, Avatar } from "antd"
import { DeleteOutlined, EyeOutlined, PlusOutlined, KeyOutlined } from '@ant-design/icons';

import moment from "moment"

import UserContext from "../../../Context/User"
import { getTokens, createToken, deleteToken } from "../../../API/Import"

export default class Token extends React.Component {
    static contextType = UserContext

    createTokenForm = React.createRef()

    state = {
        data: [],
        total: 0,
        loading: true,
        adding_token: false
    }

    componentDidMount() {
        this.getTokens()
    }

    getTokens = _ => {
        this.setState({ loading: true })

        getTokens(this.context.user.token).then(({ total, data }) => {
            this.setState({ total, data })
        }).catch(err => {
            Modal.error({
                title: "Erreur lors de la récupération des tokens",
                content: err,
                centered: true,
                maskClosable: true
            })
        }).finally(_ => {
            this.setState({ loading: false })
        })
    }

    createToken = value => {
        this.setState({ adding_token: true })
        createToken(value, this.context.user.token).then(d => {
            this.showToken(d)
            this.createTokenForm.current.resetFields()
            const { token, created, name } = d
            this.setState({ data: [...this.state.data, { _id: token, _source: { created, name } }] })
        }).catch(err => {
            Modal.error({
                title: "Erreur lors de la création du token",
                content: err,
                centered: true,
                maskClosable: true
            })
        }).finally(_ => {
            this.setState({ adding_token: false })
        })
    }

    showToken = data => {
        let name, token, created = ""

        if (data._id !== undefined) {
            name = data._source.name
            created = data._source.created
            token = data._id
        } else {
            name = data.name
            token = data.token
            created = data.created
        }

        Modal.info({
            title: "Information sur un token",
            width: 600,
            content: <><br />
                <b>Nom : {name}</b><br />
                Créé le {moment(created).format("DD/MM/YYYY [à] HH[h]mm")}<br /><br />
                <b>Token : </b>{token}<br /><br />
                <b>Veuillez en aucun cas transmettre ce token.</b><br />
                <b>Utilisez ce token pour un seul projet.</b>
            </>,
            centered: true,
            maskClosable: true
        })
    }

    deleteToken = token => {
        deleteToken(token, this.context.user.token).then(_ => {
            message.success('Token supprimé !');
            this.setState({ data: this.state.data.filter(e => e._id !== token) })
        }).catch(err => {
            Modal.error({
                title: "Erreur lors de la suppression du token",
                content: err,
                centered: true,
                maskClosable: true
            })
        })
    }

    render() {
        return <>
            <Card title="Créer un token" size='small'>
                <Form
                    ref={this.createTokenForm}
                    layout="inline"
                    hideRequiredMark
                    onFinish={this.createToken}>
                    <Form.Item
                        label="Nom du token"
                        style={{ flex: 1 }}
                        name="name"

                        rules={[{ required: true, message: 'Veuillez entrer un nom.' }]}>
                        <Input placeholder="DESKTOP-456D2" />
                    </Form.Item>

                    <Form.Item>
                        <Button
                            loading={this.state.adding_token}
                            type="primary"
                            htmlType="submit"
                            icon={<PlusOutlined />}
                        />
                    </Form.Item>
                </Form>
            </Card>

            <b style={{ display: "block", margin: "25px 0 5px" }}>Liste des tokens</b>
            <List
                loading={this.state.loading}
                dataSource={this.state.data}
                renderItem={item => {

                    return <List.Item
                        actions={[
                            <Button type='link' onClick={_ => this.showToken(item)} icon={<EyeOutlined />} />,
                            <Popconfirm
                                title="Êtes-vous sûr de vouloir supprimer ce token ?"
                                placement="topRight"
                                onConfirm={_ => this.deleteToken(item._id)}>
                                <Button type='link' icon={<DeleteOutlined />} />
                            </Popconfirm>
                        ]}>
                        <Avatar style={{ backgroundColor: "#ddd" }} icon={<KeyOutlined />} />
                        <b style={{ marginLeft: 15 }}>{item._source.name}</b>
                        {" "}
                    </List.Item>
                }}
            />
        </>
    }
}