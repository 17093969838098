import React, { Component } from 'react';

import { Result, PageHeader, Button, Spin, Form, Popconfirm, Input, Select } from "antd"

import UserContext from "../../../Context/User"
import { createUser, getUser, updateUser, deleteUser } from "../../../API/User"


export default class EditUser extends Component {

    static contextType = UserContext

    state = {
        loading: true
    }

    componentDidMount() {
        if (this.props.match.params.id !== "new") {

            getUser(this.props.match.params.id, this.context.user.token).then(data => {
                console.log(data)
                this.setState({
                    loading: false,
                    email: data["_id"],
                    role: data["_source"]["role"]
                })
            }).catch(err => {
                this.setState({
                    result: {
                        status: "error",
                        title: "Récupération utilisateur",
                        subTitle: err
                    }
                })
            })

        } else {
            this.setState({ loading: false })
        }
    }

    deleteUser = _ => {
        deleteUser(this.state.email, this.context.user.token).then(_ => {
            this.setState({
                result: {
                    status: "success",
                    title: "Utilisateur supprimé",
                    subTitle: "L'utilisateur a bien été supprimé."
                }
            })
        }).catch(err => {
            this.setState({
                result: {
                    status: "error",
                    title: "Erreur suppression",
                    subTitle: err
                }
            })
        })
    }

    onSubmitForm = data => {
        this.setState({ loadingButton: true })
        if (this.state.email) { // Mise à jour
            updateUser(this.state.email, { role: data.role }, this.context.user.token).then(_ => {
                this.setState({
                    result: {
                        status: "success",
                        title: "Utilisateur mis à jour",
                        subTitle: "L'utilisateur a bien été mis à jour."
                    }
                })
            }).catch(err => {
                this.setState({
                    result: {
                        status: "error",
                        title: "Erreur mise à jour",
                        subTitle: err
                    }
                })
            })
        } else { // Nouveau
            createUser(data, this.context.user.token).then(_ => {
                this.setState({
                    result: {
                        status: "success",
                        title: "Utilisateur créé",
                        subTitle: "L'utilisateur a bien été créé."
                    }
                })
            }).catch(err => {
                this.setState({
                    result: {
                        status: "error",
                        title: "Erreur création utilisateur",
                        subTitle: err
                    }
                })
            })
        }
    }

    render() {
        return (
            <PageHeader
                title={!this.state.loading && (this.state.email ? "Editer un utilisateur" : "Ajouter un utilisateur")}
                ghost={false}>

                {!this.state.loading && !this.state.result &&
                    <Form
                        name="basic"
                        labelCol={{ flex: "50px" }}
                        wrapperCol={{ flex: 1 }}
                        onFinish={this.onSubmitForm}
                        hideRequiredMark={true}
                        initialValues={{
                            email: this.state.email,
                            role: this.state.role
                        }}>

                        <Form.Item
                            name="email"
                            label="Email"
                            rules={[
                                {
                                    required: true,
                                    message: 'Veuillez entrer un email',
                                },
                                {
                                    type: "email",
                                    message: 'Email invalide',
                                }
                            ]}>
                            <Input disabled={this.state.email} value={this.state.email} placeholder="Email" />
                        </Form.Item>

                        <Form.Item
                            name="role"
                            label="Rôle"
                            rules={[
                                {
                                    required: true,
                                    message: 'Veuillez choisir un rôle',
                                },
                                {
                                    enum: ["admin", "auditeur", "agent"],
                                    message: 'Le rôle doit être "Administrateur", "Auditeur" ou "Agent"',
                                }
                            ]}>
                            <Select
                                placeholder="Selectionner un rôle"
                                disabled={this.state.email === this.context.user.user}
                                style={{ width: "100%" }} >
                                <Select.Option value="agent">Agent</Select.Option>
                                <Select.Option value="auditeur">Auditeur</Select.Option>
                                <Select.Option value="admin">Administrateur</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item>
                            <Button type="link" onClick={_ => this.props.history.goBack()}>
                                Annuler
                            </Button>
                            <Button
                                style={{ float: "right" }}
                                type="primary"
                                loading={this.state.loadingButton}
                                htmlType="submit">
                                Enregistrer
                            </Button>
                            {this.state.email && <Popconfirm
                                title="Êtes-vous sûr de vouloir supprimer cet utilisateur ?"
                                onConfirm={this.deleteUser}
                                disabled={this.state.email === this.context.user.user}
                                okText="Oui"
                                cancelText="Annuler" >
                                <Button disabled={this.state.email === this.context.user.user} style={{ float: "right" }} type="link" danger>Supprimer cet utilisateur</Button>
                            </Popconfirm>}
                        </Form.Item>

                    </Form>}

                {this.state.loading && !this.state.result && <div style={{ flex: 1, lineHeight: "250px", textAlign: "center" }}>
                    <Spin spinning={this.state.loading} tip="Chargement..." />
                </div>}

                {this.state.result && <Result
                    {...this.state.result}
                    extra={<Button onClick={_ => this.props.history.goBack()}>Retour</Button>}></Result>}

            </PageHeader>

        );
    }
}