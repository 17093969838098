import React, { Component } from 'react';

import { Row, Col, Form, PageHeader, Modal, Input, Button } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import UserContext from "../../Context/User"

import { Link } from 'react-router-dom';

import { LogIn as LogIn_API } from "../../API/Authentification"

export default class LogIn extends Component {
    static contextType = UserContext

    state = {
        loading: false
    }

    componentDidMount() {
        if (window.location.search.indexOf("se=true") > -1 && this.context.user.connected === false) {
            Modal.info({
                title: 'Information',
                content: "Vous avez été déconnecté, car vous êtes resté inactif trop longtemps ou vous vous êtes connecté sur un autre ordinateur.",
                centered: true,
                maskClosable: true
            })
        }
    }

    onFinish = ({ email, password }) => {
        this.setState({ loading: true })

        LogIn_API(email, password)
            .then(({ token, role, group }) => {
                setTimeout(_ => {
                    this.setState({ loading: false })
                    this.context.setUser(email, token, role, group)
                    window.location = "/"
                }, 500)
            })
            .catch(err => {
                this.setState({ loading: false })

                Modal.error({
                    title: 'Erreur lors de la connexion',
                    content: err,
                    centered: true,
                    maskClosable: true
                })
            })
    }

    render() {
        return (
            <Row justify="center" align="top">
                <Col flex={2}></Col>
                <Col flex="700px">
                    <PageHeader
                        ghost={false}
                        title="Connexion">

                        <Form
                            size="large"
                            onFinish={this.onFinish}>

                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez entrer votre email !',
                                    },
                                    {
                                        type: "email",
                                        message: 'Email invalide',
                                    }
                                ]}>
                                <Input prefix={<UserOutlined />} placeholder="Email" />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez entrer votre mot de passe !',
                                    },
                                ]}>
                                <Input.Password prefix={<LockOutlined />} placeholder="Mot de passe" />
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    loading={this.state.loading}
                                    type="primary"
                                    block
                                    htmlType="submit">Connexion</Button>
                            </Form.Item>
                        </Form>

                        <Link to="/mot-de-passe-oublie">Vous avez oublié votre mot de passe ?</Link>

                    </PageHeader>
                </Col>
                <Col flex={2}></Col>
            </Row>
        )
    }
}