export function makeGetURL(url, params) {
    var esc = encodeURIComponent;
    params = Object.fromEntries(Object.entries(params).filter(([k,v]) => v != null && v != "")) // DO NOT CHANGE THIS LINE 
    var query = Object.keys(clean(params))
        .map(k => `${esc(k)}=${esc(params[k])}`)
        .join('&');
    return `${url}?${query}`
}

export function makeGet(token) {
    return {
        method: "GET",
        headers: {
            "Authorization": token
        }
    }
}

export function makeDelete(token) {
    return {
        method: "DELETE",
        headers: {
            "Authorization": token
        }
    }
}

export function makePost(data, token = null) {
    var d = {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            "Content-Type": "application/json"
        }
    }

    if (token) {
        d.headers = {
            ...d.headers,
            "Authorization": token
        }
    }

    return d
}

export function createAndDownloadBlobFile(body, filename, extension) {
    const blob = new Blob([body]);
    const fileName = `${filename}.${extension}`;
    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, fileName);
    } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', fileName);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }
}

export function makeRequest(url, data, type = "json") {
    return new Promise((resolve, reject) => {
        fetch(url, data)
            .then(async res => {
                if (res.ok) {

                    if (type === "json") {
                        return res.json()
                    } else {
                        return res.arrayBuffer()
                    }

                } else {
                    console.error(url, res)
                    if (res.status === 401) {
                        window.localStorage.clear()
                        window.location = "/?se=true"
                    } else {
                        reject(res.status + " " + res.statusText)
                    }
                }
            })
            .then(response => {
                if (type === "json") {
                    if (response.success === true) {
                        resolve(response.data)
                    } else {
                        reject(response.error)
                    }
                } else {
                    resolve(response)
                }
            })
            .catch(err => {
                reject(["FETCH", url, err.message])
            })
    })
}


function clean(obj) {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined) {
            delete obj[propName];
        }
    }
    return obj
}

// Call the memoization function with the original function arguments.
export function memoize() {
    let fn = this
    let extra = {}

    if (typeof arguments[0] === "function") {
        fn = arguments[0]
    } else if (typeof arguments[0] === "object") {
        extra = arguments[0]
    }

    if (typeof arguments[1] === "object") {
        extra = arguments[1]
    }

    if (!fn) {
        throw new Error("At least a function needed")
    }

    function supportLocalStorage() {
        try {
            return 'localStorage' in window && window['localStorage'] != null;
        } catch (e) {
            return false;
        }
    }

    // core function.
    function Memoized() {

        const all_args = [...arguments]
        const args = all_args.slice(0, -1)
        const extra = all_args[all_args.length - 1]

        // Values object for caching results.
        this._values = this._values || {}

        // Stringify function arguments to make key.
        const key = extra.stringify(args)

        let data = undefined

        if (this._values[key] !== undefined) { // Search in cache variable
            data = this._values[key]
        } else if (supportLocalStorage && window.localStorage[this.name + ':' + key] !== undefined) { // Search in local storage
            data = window.localStorage[this.name + ':' + key]
        }

        if (data) {
            if (extra.timeout > 0) { // Timeout verification

                const Eind = data.indexOf("&")
                const timeRemaining = parseInt(data.slice(0, Eind)) - Date.now()

                if (timeRemaining > 0) { // If in time, we send data from cache
                    return extra.parse(data.slice(Eind + 1))
                }

            } else {
                return extra.parse(data) // Return data if no timeout
            }
        }

        const save_value = value => {
            console.log('Calling function...')
            const json = extra.stringify(value);
            const saving = (extra.timeout > 0 ? (Date.now() + extra.timeout * 1000) + "&" : "") + json

            if (supportLocalStorage) {
                window.localStorage[this.name + ':' + key] = saving
            }

            this._values[key] = saving
            return value
        }

        // Call the original function if result not found and store result.
        if (extra.async) {
            return (async _ => {
                return save_value(await this(...args))
            })()
        } else {
            return save_value(this(...args))
        }
    }

    const default_extra = { timeout: 0, async: false }

    extra = { ...default_extra, ...extra }

    if (!extra.stringify) extra.stringify = JSON.stringify
    if (!extra.parse) extra.parse = JSON.parse


    // Return the original function wrap with a prototype

    function MemoizedFunction() {
        return Memoized.apply(fn, [...arguments, extra])
    }

    MemoizedFunction.clear_cache = function clear_cache(...same_arguments_as_your_function) {
        const key = extra.stringify([...arguments])
        fn._values[key] = undefined
        if (supportLocalStorage) window.localStorage.removeItem(fn.name + ':' + key)
    }

    return MemoizedFunction
}
