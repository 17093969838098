import React from 'react';

import { Result, Button } from 'antd';

export default function Route404() {

    function goHome(){
        window.location = "/"
    }

    return <Result
        status="404"
        title="Erreur 404"
        subTitle="Cette page n'existe pas."
        extra={<Button onClick={goHome} type="primary">Retour à l'accueil</Button>}
    />
}