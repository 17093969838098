import React from 'react';

import { Input, DatePicker, Select, Button, Space } from 'antd';

import { SearchOutlined } from "@ant-design/icons"

import moment from "moment"
import 'moment/locale/fr'
import locale from 'antd/es/date-picker/locale/fr_FR';

import ListeNationalite from '../Data/ListeNationalite';

moment.locale('fr')

export function filterSearch({ setSelectedKeys, selectedKeys, confirm }) {
    return <div style={{ padding: 8 }} >
        <Input
            placeholder='Rechercher'
            value={selectedKeys[0]}
            onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
            onPressEnter={confirm}
            allowClear
            style={{ width: 200, marginBottom: 8 }}
        />
        <Space style={{ display: "block" }}>
            <Button
                type="primary"
                onClick={confirm}
                icon={<SearchOutlined />}
                size="small" >
                Rechercher
            </Button>
        </Space>
    </div>
}


export function filterDatePicker({ setSelectedKeys, selectedKeys, confirm }) {
    return <div style={{ padding: 8 }} >
        <DatePicker
            locale={locale}
            defaultValue={selectedKeys[0] ? moment(selectedKeys[0]) : null}
            onChange={d => {
                setSelectedKeys(d ? [d.toISOString()] : [])
                confirm()
            }}
            style={{ width: 200, display: 'block' }}
            showToday={false}
            allowClear
            autoComplete="off"
            format={"DD/MM/YYYY"} />
    </div>
}



function renderListeNationalite() {
    return ListeNationalite.map(nationalite => {
        return <Select.Option key={nationalite.id.toString()} value={`n-${nationalite.id}`}>{nationalite.name}</Select.Option>
    })
}

export function filterNationality({ setSelectedKeys, selectedKeys, confirm }) {
    return <div style={{ padding: 8 }} >
        <Select
            showSearch
            allowClear
            placeholder="Sélectionner une nationalité"
            optionFilterProp="children"
            mode="multiple"
            defaultValue={[...selectedKeys]}
            style={{ width: 225, marginBottom: 8 }}
            onChange={e => setSelectedKeys(e)}
            filterOption={(input, option) => {
                return option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }}>
            {renderListeNationalite()}
        </Select>
        <Space style={{ display: "block" }}>
            <Button
                type="primary"
                onClick={confirm}
                icon={<SearchOutlined />}
                size="small">Rechercher</Button>
        </Space>
    </div>
}