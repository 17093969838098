import React from 'react';

import { Route } from "react-router-dom";

import Layout from "./Layout"

import Home from "./Home/Home"
import List from "./List/List"
import Client from "./Client/Client"
import Settings from "./Settings/Settings"
import { Ping } from "../API/Authentification"
import UserContext from "../Context/User"

export default class Router extends React.Component {
	static contextType = UserContext
	
	test_session = null

	UNSAFE_componentWillMount() {
		Ping(this.context.user.token)
		this.test_session = setInterval(_ => {
			Ping(this.context.user.token)
		}, 60*1000)
	}

	componentWillUnmount(){
		try {
			clearInterval(this.test_session)
		}catch(e){}
	}

	render() {
		return <Layout>
			<Route exact path="/" component={Home} />
			<Route path={["/recherche", "/list/:list"]} component={List} />
			<Route path="/client/:client_id" component={Client} />
			<Route path="/settings" component={Settings} />
		</Layout>
	}
}
