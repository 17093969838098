import React, { Component } from 'react';

import { PageHeader, Table, Button, Modal } from "antd"
import { LeftOutlined, RightOutlined } from "@ant-design/icons"

import { getHistory, getHistoryAction, getAllUsers } from "../../../API/User"
import UserContext from "../../../Context/User"

import moment from "moment"

export default class History extends Component {
    static contextType = UserContext

    state = {
        loading: true,
        users: [],
        actions: [],
        total: 0,
        history: [],
        users_selected: null,
        actions_selected: null,
        last_data_of_page: []
    }

    componentDidMount() {
        this.changePage(true)

        getAllUsers(this.context.user.token, true).then(data => {
            this.setState({ users: data.data.map(e => ({ text: e.email, value: e.email })) })
        }).catch(err => {
            Modal.error({
                title: "Erreur lors de la récupération de l'historique",
                content: err,
                centered: true,
                maskClosable: true
            })
        })

        getHistoryAction(this.context.user.token).then(data => {
            this.setState({ actions: data })
        })
    }

    getData = (searchAfter = null, last_data_of_page = []) => {
        this.setState({ loading: true })

        window.scrollTo({ left: 0, top: 0, behavior: "smooth" });

        const params = {
            email: this.state.users_selected,
            action: this.state.actions_selected,
            after: searchAfter
        }

        getHistory(params, this.context.user.token).then(data => {
            this.setState({
                loading: false,
                total: data.total,
                size: data.data.length,
                history: data.data.map(v => ({ "_id": v["_id"], "sort": v["sort"][0], ...v["_source"] })),
                last_data_of_page: last_data_of_page.concat(data.data.length > 0 ? data.data[data.data.length - 1]["sort"][0] : 0)
            })
        }).catch(err => {
            Modal.error({
                title: "Erreur lors de la récupération de l'historique",
                content: err,
                centered: true,
                maskClosable: true
            })
        })
    }

    changePage = previousResult => {
        let searchAfter;
        let last_data_of_page = this.state.last_data_of_page;

        if (previousResult) { // Next page
            searchAfter = this.state.last_data_of_page[this.state.last_data_of_page.length - 1]
        } else { // Prev page
            searchAfter = this.state.last_data_of_page[this.state.last_data_of_page.length - 3]
            last_data_of_page = last_data_of_page.slice(0, -2)
        }

        this.getData(searchAfter, last_data_of_page)
    }

    onTableChange = (_, filters) => {
        this.setState({ users_selected: filters.user, actions_selected: filters.action }, _ => this.getData())
    }

    render() {

        let last_date = this.state.last_data_of_page[this.state.last_data_of_page.length - 1]

        return (
            <PageHeader
                title="Historique des utilisateurs"
                ghost={false}>

                <Table
                    dataSource={this.state.history}
                    expandable={{ childrenColumnName: "test", expandedRowRender: ({ data }) => data }}
                    loading={this.state.loading}
                    pagination={false}
                    onChange={this.onTableChange}
                    rowKey={data => data["_id"]}>

                    <Table.Column
                        title="Utilisateur"
                        dataIndex="user"
                        filters={this.state.users}
                    />
                    <Table.Column
                        title="Date"
                        dataIndex="date"
                        render={val => moment(val).format("DD/MM/YYYY [à] HH[h]mm")}
                    />
                    <Table.Column
                        title="Action"
                        dataIndex="action"
                        filters={this.state.actions.map(e => ({ text: e, value: e }))}
                    />
                </Table>


                <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }} >
                    {this.state.last_data_of_page.length > 1 && <Button onClick={_ => this.changePage(false)} icon={<LeftOutlined />} />}
                    {last_date !== 0 && <Button onClick={_ => this.changePage(true)} icon={<RightOutlined />} />}
                </div>

            </PageHeader>
        );
    }
}