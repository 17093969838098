import React from 'react'

import { Tag, Descriptions } from 'antd';

import ListeNationalite from '../Data/ListeNationalite';

import moment from "moment"

import Title from "../../Utils/Title"

export default function PersonInformation({ person = [], bold = false, marginTop = false, showTypeClient = false }) {
    return Object.entries(person).length === 0 ? null : <Descriptions
        bordered
        size="small"
        style={marginTop ? { marginTop: 10 } : {}}
        column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}>

        {
            Object.entries(person).map(d => {

                let key = d[0]
                let val = d[1]

                let champs_inutiles = ["TYPE", "SCORE", "COMPARAISON"]
                if (showTypeClient === false) champs_inutiles.push("TYPECLIENT")

                // Suppresion des champs inutiles pour l'affichage
                if (champs_inutiles.includes(key)) {
                    return ""
                }

                var data = val

                // Conversion des champs nationalites num en français
                if (key === "NATIONALITE") {
                    if (Array.isArray(val)) {
                        val = val.map(val => {
                            try {
                                val = parseInt(val)
                            } catch (e) { }

                            return ListeNationalite.find(e => e.id === val)
                        }).filter(e => e !== undefined).map(e => e.name)
                    } else {
                        try {
                            val = ListeNationalite.find(e => e.id === parseInt(val))
                            val = [val !== undefined ? val.name : "Code pays non trouvé"]
                        } catch (e) {
                            val = ["Code pays non trouvé"]
                        }
                    }

                    data = val.map(e => <Tag key={e}>{e}</Tag>)
                }

                if (key === "PRENOM") {
                    data = Title(data)
                }

                if (key === "QUALITE") {
                    data = Array.isArray(val) ? val.map(e => <Tag key={e}>{e}</Tag>) : <Tag key={val}>{val}</Tag>
                } else {
                    if (Array.isArray(val)) data = val.map(e => Title(e)).join(", ")
                }

                if (key.startsWith("DATE")) {
                    data = moment(data).format("DD/MM/YYYY")
                }

                // Node final
                return <Descriptions.Item key={key} label={changeColumnName(key)}><span style={bold ? { fontWeight: "bold" } : {}}>{data}</span></Descriptions.Item>
            })

        }

    </Descriptions>
}

function changeColumnName(str) {
    switch (true) {
        case /RAISONSOCIALE/.test(str):
            return "Raison sociale"
        case /DATENAISSANCE/.test(str):
            return "Date de naissance"
        case /DATEDECES/.test(str):
            return "Date de décès"
        case /NOMNAISSANCE/.test(str):
            return "Nom de naissance"
        case /^ID/.test(str):
            return str.toUpperCase()
        case /TYPECLIENT/.test(str):
            return "Type de client"
        default:
            return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
    }
}