import React, { Component } from 'react';
import { Link } from "react-router-dom"
import { PageHeader, Button, List, Card, Spin, Modal } from "antd"

import { EditOutlined } from '@ant-design/icons'

import { getAllGroups } from "../../../API/Group"
import UserContext from "../../../Context/User"

export default class Groups extends Component {
    static contextType = UserContext

    state = {
        loading: true,
        data: null
    }

    componentDidMount() {
        getAllGroups(this.context.user.token).then(({ total, data }) => {
            this.setState({ data, loading: false })
        }).catch(err => {
            Modal.error({
                title: "Erreur lors de la récupération des groupes",
                content: err,
                centered: true,
                maskClosable: true
            })
        })
    }

    render() {
        return (
            <PageHeader
                title="Gestion des groupes"
                extra={<Button onClick={_ => this.props.history.push("/settings/group/new")} type="primary">Créer</Button>}
                ghost={false}>

                {
                    !this.state.loading &&
                    <List
                        grid={{ gutter: 16, column: 2 }}
                        dataSource={this.state.data}
                        renderItem={item => (
                            <List.Item>
                                <Card
                                    size="small" title={item._source.name}
                                    extra={<Link to={"/settings/group/" + item._id}><Button type="dashed" icon={<EditOutlined />}></Button></Link>}>
                                    TAG : {item._id}
                                </Card>
                            </List.Item>
                        )}
                    />
                }

                {
                    this.state.loading &&
                    <div style={{ flex: 1, lineHeight: "250px", textAlign: "center" }}>
                        <Spin spinning={this.state.loading} tip="Chargement..." />
                    </div>
                }

            </PageHeader>
        );
    }
}