import React from 'react'

import { Modal, Alert, Comment, Form, Input, List, Button } from 'antd';

import { getClientCommentById, postClientCommentById } from '../../API/List';

import moment from "moment"

const FORMAT_WHEN = "DD/MM/YYYY [à] HH[h]mm"

export default class CommentSpace extends React.Component {

    refForm = React.createRef()

    state = {
        comment: [],
        comment_total: 0
    }

    componentDidMount() {
        // On récupère les premiers commentaires
        this.getComments()
    }

    // Récupération des commentaires
    getComments = (after = null) => {
        if (after != null) after = this.state.comment[this.state.comment.length - 1]["sort"][0]

        getClientCommentById(this.props.client_id, this.props.user.token, after, 5).then(data => {
            this.setState(s => ({ comment: after ? [...s.comment, ...data.data] : data.data, comment_total: data.total }))
        }).catch(err => {
            this.setState({ comment_error: true })
        })
    }

    // Lors de l'ajout d'un commentaire
    onCommentAdded = ({ message }) => {
        this.setState({ adding_comment: true })

        postClientCommentById(this.props.client_id, { message }, this.props.user.token).then(id => {
            let comment = {
                _id: id,
                _source: {
                    MESSAGE: message,
                    USER: this.props.user.user,
                    CREATED: new Date(),
                }
            }
            this.setState(s => ({ comment: [comment, ...s.comment], comment_total: s.comment_total + 1, adding_comment: false }))
            this.refForm.current.resetFields()
        }).catch(err => {
            this.setState({ adding_comment: false })
            Modal.error({
                title: "Erreur lors de l'ajout du commentaire",
                content: err,
                centered: true,
                maskClosable: true
            })
        })
    }

    render() {
        if (this.state.comment_error) {
            return <Alert
                style={{ marginTop: 15 }}
                message={`Erreur lors de la récupération des commentaires`}
                type="error"
                showIcon
            />
        } else { // Si pas d'erreur lors de la récupération des commentaires
            return <>

                {
                    this.state.comment.length > 0 &&
                    <List
                        dataSource={this.state.comment}
                        size={"small"}
                        renderItem={item => {
                            return <li>
                                <Comment
                                    author={item._source.USER}
                                    content={item._source.MESSAGE}
                                    datetime={moment(item._source.CREATED).format(FORMAT_WHEN)}
                                />
                            </li>
                        }}
                    />
                }

                {
                    this.state.comment_total > this.state.comment.length &&
                    <div style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}>
                        <Button onClick={_ => this.getComments(true)} type="link">Voir plus de commentaire</Button>
                    </div>
                }

                <Form ref={this.refForm} onFinish={e => this.onCommentAdded(e)}>
                    <Form.Item name="message">
                        <Input.TextArea rows={1} />
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" loading={this.state.adding_comment} type="primary">Ajouter</Button>
                    </Form.Item>
                </Form>

            </>
        }
    }
}