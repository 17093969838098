import React, { Component } from 'react';
import { PageHeader, Button, Input, Form, Spin, Result } from "antd"

import UserContext from "../../../Context/User"
import { getGroup, createEditGroup } from "../../../API/Group"

export default class Group extends Component {
    static contextType = UserContext

    state = {
        loading: true,
        saving: false,
        group_tag: null,
        new: false,
        name: ""
    }

    componentDidMount() {
        const group_tag = this.props.match.params.id
        this.setState({ group_tag })

        if (group_tag === "new") {
            this.setState({ loading: false, new: true })
        } else {
            getGroup(group_tag, this.context.user.token).then(data => {
                this.setState({ loading: false, new: false, name: data._source.name })
            }).catch(err => {
                this.setState({ loading: false, result: { status: "error", title: err } })
            })
        }
    }

    save = values => {
        this.setState({ saving: true })

        if (this.state.group_tag === "new") values.new = true

        createEditGroup(values, this.context.user.token).then(data => {
            const title = data === "created" ? "Groupe créé !" : data === "updated" ? "Groupe modifié !" : data
            this.setState({ saving: false, result: { status: "success", title } })
        }).catch(err => {
            this.setState({ saving: false, result: { status: "error", title: err } })
        })
    }

    resultBackButton = _ => {
        if (this.state.result.status === "success") {
            this.props.history.goBack()
        } else {
            this.setState({ result: null })
        }
    }

    render() {
        return (
            <PageHeader
                title={this.state.group_tag === "new" ? "Créer un groupe" : "Modifier un groupe"}
                onBack={this.props.history.goBack}
                ghost={false}>

                {!this.state.loading && !this.state.result &&

                    <Form
                        layout="vertical"
                        initialValues={{
                            name: this.state.name,
                            tag: this.state.new ? "" : this.state.group_tag
                        }}
                        onFinish={this.save}>

                        <Form.Item
                            label="Nom du groupe"
                            name="name"
                            rules={[{ required: true, message: 'Veuillez entrer le nom du groupe.' }]}>
                            <Input placeholder="Exemple : Crédit Municipal de Nantes" />
                        </Form.Item>

                        <Form.Item
                            label="Tag du groupe"
                            name="tag"
                            rules={[
                                { required: true, message: 'Veuillez entrer un tag pour le groupe.' },
                                { pattern: /^[a-z]+$/, message: 'Veuillez utiliser uniquement l\'alphabet en minuscule.' },
                            ]}>
                            <Input placeholder="Exemple : cmn" disabled={this.state.group_tag !== "new"} />
                        </Form.Item>

                        {
                            this.state.new &&
                            <Form.Item
                                label="Administrateur du groupe"
                                name="admin"
                                extra="Il recevra automatiquement un mail avec son mot de passe."
                                rules={[
                                    { required: true, message: 'Veuillez entrer un administrateur.' },
                                    { type: "email", message: 'Veuillez entrer un email valide.' },
                                ]}>
                                <Input />
                            </Form.Item>
                        }

                        <Form.Item>
                            <Button loading={this.state.saving} type="primary" htmlType="submit">{this.state.new ? "Créer" : "Modifier"}</Button>
                        </Form.Item>
                    </Form>
                }

                {this.state.loading && !this.state.result && <div style={{ flex: 1, lineHeight: "250px", textAlign: "center" }}>
                    <Spin spinning={this.state.loading} tip="Chargement..." />
                </div>}

                {this.state.result && <Result
                    {...this.state.result}
                    extra={<Button onClick={this.resultBackButton}>Retour</Button>}></Result>}

            </PageHeader>

        );
    }
}