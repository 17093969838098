import React, { Component } from 'react';

import { Link, withRouter } from 'react-router-dom';

import { Row, Col, Menu, Layout } from 'antd';
import { LogoutOutlined } from "@ant-design/icons"

import UserContext from "../Context/User"
import hasEnoughRight, { ROLE } from "../Utils/hasEnoughRight"

class Layout_Custom extends Component {
    static contextType = UserContext

    state = {
        menu_link_selected: []
    }

    componentDidMount() {
        this.change_menu_link(this.props.location)
        this.props.history.listen(this.change_menu_link)
    }

    change_menu_link = ({ pathname }) => {
        let now = pathname.indexOf("settings") > -1 ? "/settings" : pathname
        this.setState({ menu_link_selected: [now] })
    }

    render() {
        const { user } = this.context
        return (
            <Layout style={{ minHeight: "100vh" }}>

                <Layout.Header style={{ position: 'fixed', zIndex: 1, width: '100%', height: "fit-content" }}>

                    <Row justify="center">
                        <Col span={24} lg={20}>

                            <Link to="/">
                                <div className="logo-block">
                                    <img src="/icon.svg" alt="Crédit Municipal de Nantes" className="logo" />
                                </div>
                            </Link>

                            <Menu theme="dark" mode="horizontal" style={{ float: "right" }}>
                                <Menu.Item onClick={this.context.logOut} key="/"><LogoutOutlined /> Déconnexion</Menu.Item>
                            </Menu>

                            <Menu theme="dark" mode="horizontal" selectedKeys={this.state.menu_link_selected} >
                                <Menu.Item key="/"><Link to="/">Accueil</Link></Menu.Item>

                                <Menu.SubMenu title="Listes de contrôle">
                                    <Menu.Item key="/list/datalake-gel"><Link to="/list/datalake-gel">Liste GEL</Link></Menu.Item>
                                    <Menu.Item key="/list/datalake-ppe"><Link to="/list/datalake-ppe">Liste PPE</Link></Menu.Item>
                                    <Menu.Item key="https://deces.matchid.io/search"><a href="https://deces.matchid.io/search" rel="noopener noreferrer" target="_blank">Liste RNIPP</a></Menu.Item>
                                </Menu.SubMenu>

                                <Menu.SubMenu title="Client">
                                    <Menu.Item key="/list/client"><Link to="/list/client">Référentiel Client</Link></Menu.Item>
                                    {hasEnoughRight(user.role, ROLE.Auditeur) && <Menu.Item key="/list/controle-auditeur"><Link to="/list/controle-auditeur">Contrôle auditeur</Link></Menu.Item>}
                                </Menu.SubMenu>

                                <Menu.Item key="/settings"><Link to="/settings">Paramètres</Link></Menu.Item>
                            </Menu>

                        </Col>
                    </Row>

                </Layout.Header>

                <Layout.Content className="site-layout" style={{ padding: "0 25px 0", marginTop: 125 }}>
                    <Row justify="center">
                        <Col span={24} lg={20}>
                            {this.props.children}
                        </Col>
                    </Row>
                </Layout.Content>
                <Layout.Footer style={{ textAlign: 'center' }}>© Crédit Municipal de Nantes - 2020</Layout.Footer>
            </Layout >
        );
    }
}

export default withRouter(Layout_Custom)
