import { BASE_URL } from "./Config"
import { makeGet, makePost, makeRequest } from "./Util"

export function getAllGroups(token) {
    return makeRequest(`${BASE_URL}/groups`, makeGet(token))
}

export function getGroup(group_tag, token) {
    return makeRequest(`${BASE_URL}/groups/${group_tag}`, makeGet(token))
}

export function createEditGroup(data, token) {
    return makeRequest(`${BASE_URL}/groups`, makePost(data, token))
}

export function changegroup(group_tag, token) {
    return makeRequest(`${BASE_URL}/groups/${group_tag}/change-group`, makeGet(token))
}