import React, { Component } from 'react';

import { PageHeader, Form, Button, Input, Card, Modal } from "antd"

import { changePassword } from "../../../API/User"
import UserContext from "../../../Context/User"


export default class Account extends Component {
    static contextType = UserContext

    form_change_password = React.createRef()
    new_password = React.createRef()

    change_password = values => {
        const { actual_password, new_password } = values

        changePassword(this.context.user.token, { actual_password, new_password }).then(_ => {
            this.form_change_password.current.resetFields()

            const modal = Modal.success({
                centered: true,
                title: 'Changement de mot de passe',
                content: 'Le mot de passe a bien été modifié.',
            })

            setTimeout(modal.destroy, 5000)

        }).catch(err => {
            Modal.error({
                centered: true,
                title: 'Changement de mot de passe',
                content: err,
            })
        })
    }

    render() {
        return (
            <PageHeader
                title="Mon compte"
                ghost={false}>

                <Card size="small" title="Changer de mot de passe">
                    <Form
                        ref={this.form_change_password}
                        labelCol={{ span: 7 }}
                        onFinish={this.change_password}>

                        <Form.Item
                            label="Mot de passe"
                            name="actual_password"
                            rules={[
                                { required: true, message: 'Veuillez entrer votre mot de passe actuel.' },
                            ]}>
                            <Input.Password />
                        </Form.Item>

                        <Form.Item
                            label="Nouveau mot de passe"
                            name="new_password"
                            rules={[
                                { required: true, message: 'Veuillez entrer votre nouveau mot de passe.' },
                                { min: 5, message: '5 caractères minimum.' }
                            ]}>
                            <Input.Password ref={this.new_password} />
                        </Form.Item>

                        <Form.Item
                            label="Retaper le mot de passe"
                            name="new_password_2"
                            rules={[
                                { required: true, message: 'Veuillez entrer votre retaper votre nouveau mot de passe.' },
                                {
                                    validator: (_, val) => {
                                        return new Promise((resolve, reject) => {
                                            if (val === this.new_password.current.input.value) {
                                                resolve()
                                            } else {
                                                reject("Mot de passe différent")
                                            }
                                        })
                                    }, message: 'Les mots de passes sont différents.'
                                }

                            ]}>
                            <Input.Password />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 7 }} >
                            <Button type="primary" htmlType="submit">Changer de mot de passe</Button>
                        </Form.Item>
                    </Form>
                </Card>
            </PageHeader>
        );
    }
}