import { BASE_URL } from "./Config"
import { makePost, makeGet, makeRequest } from "./Util"

export function LogIn(email, password) {
    return makeRequest(`${BASE_URL}/auth`, makePost({ email, password }))
}

export function ForgottenPassword(email) {
    return makeRequest(`${BASE_URL}/auth/reset-password`, makePost({ email }))
}

export function ResetPassword(reset_id, values) {
    return makeRequest(`${BASE_URL}/auth/reset-password/${reset_id}`, makePost(values))
}

export function Ping(token) {
    return makeRequest(`${BASE_URL}/auth/ping`, makeGet(token))
}