import React, { Component } from 'react';
import { PageHeader, Button, Form, Spin, Result, Select, Modal, Input } from "antd"

import { getAllGroups, changegroup } from "../../../API/Group"
import UserContext from "../../../Context/User"

export default class Groups extends Component {
    static contextType = UserContext

    state = {
        loading: true,
        saving: false,
        result: null
    }

    componentDidMount() {
        getAllGroups(this.context.user.token).then(({ total, data }) => {
            this.setState({ data, loading: false })
        }).catch(err => {
            Modal.error({
                title: "Erreur lors de la récupération des groupes",
                content: err,
                centered: true,
                maskClosable: true
            })
        })
    }

    changeGroup = ({ group }) => {

        this.setState({ saving: true })

        changegroup(group, this.context.user.token).then(d => {
            this.context.changeGroup(group)
            this.setState({ saving: false, result: { status: "success", title: "Vous venez de changer de groupe !" } })
        }).catch(err => {
            this.setState({ saving: false, result: { status: "error", title: err } })
        })
    }

    render() {

        // Récupération du nom du groupe actuel
        let actual_group;
        try {
            actual_group = this.state.data.find(e => e._id === this.context.user.group)._source.name
        } catch (err) {
            actual_group = "Erreur lors de l'obtention du group actuel."
        }

        return (
            <PageHeader
                title="Changer de groupe"
                ghost={false}>

                {!this.state.loading && !this.state.result &&

                    <Form
                        layout="vertical"
                        initialValues={{ actual_group }}
                        hideRequiredMark={true}
                        onFinish={this.changeGroup}>

                        <Form.Item
                            label="Groupe actuel"
                            name="actual_group">
                            <Input disabled />
                        </Form.Item>

                        <Form.Item
                            label="Groupe de destination"
                            name="group"
                            rules={[{ required: true, message: 'Veuillez choisir un groupe.' }]}>
                            <Select placeholder="Veuillez selectionner un groupe">
                                {this.state.data.map(e => {
                                    return <Select.Option key={e._id} value={e._id}>{e._source.name}</Select.Option>
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item>
                            <Button loading={this.state.saving} type="primary" htmlType="submit">Changer</Button>
                        </Form.Item>
                    </Form>
                }

                {this.state.loading && !this.state.result && <div style={{ flex: 1, lineHeight: "250px", textAlign: "center" }}>
                    <Spin spinning={this.state.loading} tip="Chargement..." />
                </div>}

                {this.state.result && <Result {...this.state.result}></Result>}

            </PageHeader>
        );
    }
}