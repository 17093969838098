import React from 'react'

import { Tag, Typography, Alert, List, Tooltip, Button, Popconfirm } from 'antd';
import { GlobalOutlined } from "@ant-design/icons"

import Title from "../../Utils/Title"

import PersonInformation from "./PersonInformation"

export default function MatchDisplay({ match_list = [], onChangeMatchStatus }) {
    return <List
        dataSource={match_list}
        renderItem={match => <MatchRow match={match} onChangeMatchStatus={onChangeMatchStatus} />}
    />
}

export function MatchRow({ match, onChangeMatchStatus }) {
    const type = match.INDEX_MATCH.replace("datalake-", "").toUpperCase()

    const SOURCE = match.INDEX_MATCH === "rnipp" ? match.DATA_MATCH : match.data._source

    const { NOM, PRENOM, RAISONSOCIALE } = SOURCE

    const URL = "https://qwant.com/?q=" + encodeURIComponent(`${Title(PRENOM)} ${Title(NOM)} ${Title(RAISONSOCIALE)}`) + "&t=web"

    return <List.Item style={{ display: "block" }}>

        <Typography.Text strong>

            <Tooltip title="Rechercher sur Qwant">
                <a href={URL} target="_blank" rel="noopener noreferrer" style={{ marginRight: 10 }}>
                    <GlobalOutlined />
                </a>
            </Tooltip>

            Comparaison {type}

            <Tag color="red" style={{ float: 'right', fontSize: 13, fontWeight: "bold" }}>Score : {match.SCORE}</Tag>
        </Typography.Text>

        {
            match.data?.found || !!SOURCE ?
                <PersonInformation person={SOURCE} marginTop />
                :
                <Alert
                    style={{ marginTop: 15 }}
                    message={`Correspondance ${type} introuvable`}
                    type="error"
                    showIcon
                />
        }


        <div style={{ display: "flex", justifyContent: "space-between", marginTop: 5 }}>

            {
                match.APPROVED || match.REMOVED ?
                    <>
                        Comparaison {match.APPROVED ? 'validée' : 'refusée'} par {match.APPROVED ? match.APPROVED_BY : match.REMOVED_BY}
                        <Popconfirm
                            title="Etes vous sûr de vouloir annuler la comparaison ?"
                            onConfirm={_ => onChangeMatchStatus(match.INDEX_MATCH, match.ID_MATCH, "CANCELLED")}
                            placement="bottomRight"
                            okText="Oui"
                            cancelText="Annuler">
                            <Button type="link" danger>Annuler la comparaison</Button>
                        </Popconfirm>
                    </>
                    :
                    <>
                        <Popconfirm
                            title="Etes vous sûr de vouloir confirmer la comparaison ?"
                            onConfirm={_ => onChangeMatchStatus(match.INDEX_MATCH, match.ID_MATCH, "APPROVED")}
                            placement="bottomLeft"
                            okText="Oui"
                            cancelText="Annuler">
                            <Button style={{ color: "#1890ff" }} type="link">Confirmer la comparaison</Button>
                        </Popconfirm>

                        <Popconfirm
                            title="Etes vous sûr de vouloir refuser la comparaison ?"
                            onConfirm={_ => onChangeMatchStatus(match.INDEX_MATCH, match.ID_MATCH, "REMOVED")}
                            placement="bottomRight"
                            okText="Oui"
                            cancelText="Annuler">
                            <Button type="link" danger>Refuser la comparaison</Button>
                        </Popconfirm>
                    </>
            }
        </div>
    </List.Item>
}
