import { BASE_URL } from "./Config"
import { makeGetURL, makeGet, makePost, makeRequest, memoize } from "./Util"

// eslint-disable-next-line no-extend-native
Function.prototype.memoize = memoize

export function searchInto(lists, params, token) {
    let lists_url = `${BASE_URL}/list/search/${lists.join(",")}`
    return makeRequest(makeGetURL(lists_url, params), makeGet(token))
}

export function getPersonByIdFrom(list, person_id, token) {
    let lists_url = `${BASE_URL}/list/${list}/${person_id}`
    return makeRequest(lists_url, makeGet(token))
}

export function changeMatchStatus(client_id, match_index, match_id, status, token) {
    let lists_url = `${BASE_URL}/list/match/${client_id}/${match_index}/${match_id}`
    return makeRequest(lists_url, makePost({ status }, token))
}





export function getClientCommentById(client_id, token, after = null, size = null) {
    let params = { after, size }
    return makeRequest(makeGetURL(`${BASE_URL}/list/comment/${client_id}`, params), makeGet(token))
}

export function postClientCommentById(client_id, body, token) {
    let lists_url = `${BASE_URL}/list/comment/${client_id}`
    return makeRequest(lists_url, makePost(body, token))
}



function getPersonFrom_API(list, params, token) {
    let lists_url = `${BASE_URL}/list/${list}`
    return makeRequest(makeGetURL(lists_url, params), makeGet(token))
}

export const getPersonFrom = getPersonFrom_API.memoize({ async: true, timeout: 60 })

export function downloadList(list, params, type, token) {
    let lists_url = `${BASE_URL}/list/${list}/${type}`
    return makeRequest(makeGetURL(lists_url, params), makeGet(token), "download")
}
