import React from 'react';

import { Modal, Steps, List, Button, Avatar } from "antd"
import { CheckOutlined, WarningOutlined, LoadingOutlined, ExclamationOutlined, QuestionOutlined } from '@ant-design/icons';

import moment from "moment"

import UserContext from "../../../Context/User"
import { getHistory } from "../../../API/Import"

const ERROR_TYPE = {
    "SUCCESS": {
        COLOR: '#689F38',
        ICON: CheckOutlined
    },
    "WARNING": {
        COLOR: '#FFA000',
        ICON: WarningOutlined
    },
    "ERROR": {
        COLOR: '#E64A19',
        ICON: ExclamationOutlined
    },
    "PROCESSING": {
        COLOR: '#2196F3',
        ICON: LoadingOutlined
    },
    "": {
        COLOR: '#ccc',
        ICON: QuestionOutlined
    }
}

const ERROR_MESSAGE = {
    "DOUBLON": "Certains clients sont en double dans vos données.",
    "UTF8": "Le fichier importé n'est pas encodé en UTF-8.",
    "HEADER_SEPARATOR": "Le fichier importé ne respecte pas l'en-tête nécessaire ou le délémiteur n'est pas une virgule ','.",
    "INTERNAL": "Erreur interne lors du traitement de votre demande.",
}

export default class History extends React.Component {
    static contextType = UserContext

    state = {
        total: 0,
        data: []
    }

    componentDidMount() {
        this.getData()
    }

    getData = _ => {
        this.setState({ loading: true })

        let params = {}

        if (this.state.data.length > 0) {
            params["after"] = this.state.data[this.state.data.length - 1].sort[0]
        }

        getHistory(params, this.context.user.token).then(({ total, data }) => {
            this.setState({ total, data: [...this.state.data, ...data] })
        }).catch(err => {
            Modal.error({
                title: "Erreur lors de la récupération de l'historique",
                content: err,
                centered: true,
                maskClosable: true
            })
        }).finally(_ => {
            this.setState({ loading: false })
        })
    }

    transformLog = val => {

        let type = val._source.type
        let data = val._source.data
        let type_raw = type
        let result
        let message

        switch (type_raw) {
            case "IMPORT":
                type = "Importation"
                break
            case "MATCH":
                type = "Comparaison"
                break
            default:
                break;
        }

        if (Object.keys(ERROR_MESSAGE).includes(val._source.action)) {
            result = "ERROR"
            message = ERROR_MESSAGE[val._source.action]
        } else if (val._source.action === "SUCCESS") {
            result = "SUCCESS"
        } else {
            result = ""
            if(type_raw ==="MATCH") {
                message = ``
            } else {
                message = `Evenement du type : ${val._source.action}`
            }
        }

        return { type, type_raw, result, data, message, error: val._source.error, date: moment(val._source.date).format("DD/MM/YYYY [à] HH[h]mm") }
    }

    render() {

        const loadMore = this.state.data.length < this.state.total ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}>
                <Button onClick={this.getData}>Voir plus</Button>
            </div>
        ) : null

        return <List
            loading={this.state.loading}
            loadMore={loadMore}
            dataSource={this.state.data}
            renderItem={item_raw => {

                const item = this.transformLog(item_raw)

                let result_match = { GEL: { description: "" }, PPE: {}, MAIL: {} }

                const transformState = {
                    "doing": "process",
                    "done": "finish",
                    "error": "error"
                }

                let log_result = item.result

                if (item.type_raw === "MATCH") {
                    let match_state = []
                    for (let obj of Object.keys(result_match)) {
                        if (item.data[obj]?.data) {
                            result_match[obj].status = transformState[item.data[obj]?.data.state]

                            match_state.push(result_match[obj].status)

                            switch (result_match[obj].status) {
                                case "process":
                                    result_match[obj].description = ""
                                    result_match[obj].icon = <LoadingOutlined />
                                    break;
                                case "finish":
                                    let s1 = item.data[obj].data.added > 1 ? "s" : ""
                                    let s2 = item.data[obj].data.removed > 1 ? "s" : ""
                                    result_match[obj].description = <>{`${item.data[obj].data.added} ajouté${s1}`}<br />{`${item.data[obj].data.removed} supprimé${s2}`}</>

                                    if (obj === "MAIL") {
                                        result_match[obj].description = "Envoyé !"
                                    }

                                    break;
                                case "error":
                                    result_match[obj].description = "Une erreur est survenue."
                                    break;
                                default:
                                    break;
                            }
                        }

                    }

                    log_result = match_state.includes("process") ? "PROCESSING" : match_state.includes("error") ? "ERROR" : "SUCCESS"
                }

                if(!!item.error) { // Erreur général du script de comparaison
                    log_result = "ERROR"
                    item.message = "Une erreur est survenue."
                }

                const { COLOR, ICON } = ERROR_TYPE[log_result]

                return <List.Item style={{ flexDirection: "column", alignItems: "flex-start" }}>
                    <List.Item.Meta
                        avatar={<Avatar style={{ backgroundColor: COLOR }} icon={<ICON />} />}
                        title={item.type + " le " + item.date}
                        description={item.type_raw !== "MATCH" ? ((item.result === "SUCCESS" || !item.message) ? "Rien à signaler." : item.message) : (item.message)}
                    />
                    {
                        item.type_raw === "MATCH" && !item.error &&
                        <Steps current={1} size="small" style={{ marginTop: 15 }}>
                            {Object.keys(result_match).map(d => <Steps.Step key={d} title={d} {...result_match[d]} />)}
                        </Steps>
                    }
                </List.Item>
            }}
        />
    }

}