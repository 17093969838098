import React, { Component } from 'react';

import { Row, Col, Layout } from 'antd';

export default class Layout_Custom extends Component {
    render() {
        return (
            <Layout style={{ minHeight: "100vh" }}>

                <Layout.Header style={{ position: 'fixed', zIndex: 1, width: '100%', height: "fit-content" }}>

                    <Row justify="center">
                        <Col span={24} lg={20} style={{display:"flex", justifyContent:"center"}}>
                            <div className="logo-block">
                                <img src="/icon.svg" alt="Crédit Municipal de Nantes" className="logo" />
                            </div>
                        </Col>
                    </Row>

                </Layout.Header>

                <Layout.Content className="site-layout" style={{ padding: 50, marginTop: 50 }}>
                    <Row justify="center">
                        <Col span={24} lg={20}>
                            {this.props.children}
                        </Col>
                    </Row>
                </Layout.Content>
                <Layout.Footer style={{ textAlign: 'center' }}>© Crédit Municipal de Nantes - 2020</Layout.Footer>
            </Layout >
        );
    }
}