import React, { Component } from 'react';

import { Row, Col, Form, PageHeader, Modal, Input, Button } from 'antd';

import UserContext from "../../Context/User"

import { ResetPassword as ResetPassword_API } from "../../API/Authentification"

export default class ResetPassword extends Component {
    static contextType = UserContext

    new_password = React.createRef()

    state = {
        loading: false,
        reset_id: this.props.match.params.reset_id
    }

    onFinish = ({ new_password }) => {
        this.setState({ loading: true })

        ResetPassword_API(this.state.reset_id, { new_password })
            .then(d => {
                this.setState({ loading: false })

                console.log(d)

                Modal.success({
                    title: "Réinitialisation du mot de passe",
                    content: "Votre mot de passe a bien été changé. Vous allez être redirigé vers la page de connexion.",
                    center: true
                })

                setTimeout(_ => { window.location = "/" }, 2500)

            })
            .catch(err => {
                this.setState({ loading: false })

                Modal.error({
                    title: 'Réinitialisation du mot de passe',
                    content: err,
                    centered: true,
                    maskClosable: true
                })

            })
    }

    render() {
        return (
            <Row justify="center" align="top">
                <Col flex={2}></Col>
                <Col flex="700px">
                    <PageHeader
                        ghost={false}
                        extra={this.state.reset_id}
                        title="Réinitialiser votre mot de passe">

                        <Form
                            layout="vertical"
                            size="large"
                            onFinish={this.onFinish}>

                            <Form.Item
                                label="Nouveau mot de passe"
                                name="new_password"
                                rules={[
                                    { required: true, message: 'Veuillez entrer votre nouveau mot de passe.' },
                                    { min: 5, message: '5 caractères minimum.' }
                                ]}>
                                <Input.Password autoComplete='new-password' ref={this.new_password} />
                            </Form.Item>

                            <Form.Item
                                label="Retaper le mot de passe"
                                name="new_password_2"
                                rules={[
                                    { required: true, message: 'Veuillez entrer votre retaper votre nouveau mot de passe.' },
                                    {
                                        validator: (_, val) => {
                                            return new Promise((resolve, reject) => {
                                                if (val === this.new_password.current.input.value) {
                                                    resolve()
                                                } else {
                                                    reject("Mot de passe différent")
                                                }
                                            })
                                        }, message: 'Les mots de passes sont différents.'
                                    }

                                ]}>
                                <Input.Password autoComplete='new-password' />
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    loading={this.state.loading}
                                    type="primary"
                                    block
                                    htmlType="submit">Réinitialiser votre mot de passe</Button>
                            </Form.Item>
                        </Form>
                    </PageHeader>
                </Col>
                <Col flex={2}></Col>
            </Row>
        )
    }
}