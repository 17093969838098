import React from 'react'
import { message } from 'antd';
import { withRouter } from "react-router-dom";

const UserContext = React.createContext()

var storage = window.localStorage

class UserProvider_Class extends React.Component {

    constructor(props) {
        super(props)
        var sessionData = storage.getItem("sessionData")

        if (sessionData) {
            try {
                sessionData = JSON.parse(sessionData)
                sessionData.connected = true
            } catch (e) {
                storage.clear()
                console.error("Lecture de la session impossible")
            }
        }

        this.state = {
            connected: false,
            token: "",
            user: "",
            group: "",
            role: "",
            ...sessionData
        }
    }

    setUser = (user, token, role, group) => {
        const sessionData = { user, token, role, group }
        this.setState({ ...sessionData, connected: true })
        storage.setItem("sessionData", JSON.stringify(sessionData))
    }

    changeGroup = group => {
        this.setState({ group })
        const sessionData = { user: this.state.user, token: this.state.token, role: this.state.role, group }
        storage.setItem("sessionData", JSON.stringify(sessionData))
    }


    logOut = _ => {
        // TODO: Appel API pour détruire le token

        this.props.history.replace("/")

        storage.clear()
        this.setState({ connected: false })

        message.info("Vous êtes maintenant déconnecté.")
    }

    render() {
        const user = this.state
        const { setUser, logOut, changeGroup } = this

        return (
            <UserContext.Provider
                value={{
                    user,
                    setUser,
                    changeGroup,
                    logOut
                }}>
                {this.props.children}
            </UserContext.Provider>
        )
    }
}


export const UserProvider = withRouter(UserProvider_Class)

export const UserConsumer = UserContext.Consumer

export default UserContext