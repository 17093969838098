import React from 'react';

import { PageHeader, Tabs } from "antd"

import Token from "./Token"
import History from "./History"

export default class Repositories extends React.Component {

    render() {
        return (
            <PageHeader
                title="Référentiel client"
                ghost={false}>

                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab="Historique" key="1">
                        <History />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Token" key="2">
                        <Token />
                    </Tabs.TabPane>
                </Tabs>

            </PageHeader>
        );
    }
}