import React from 'react';

import { Row, Col } from 'antd';

export default function MaxScore({ maxScoreGEL, maxScorePPE }) {
    // les couleurs sont les meme que les tags
    function getColor(score) {
        if (score >= 80) {
            return 'red';
        }

        if (score > 75) {
            return 'orange';
        }

        if (score === 75) {
            return 'gold';
        }

        return '';
    }

    const colorGEL = getColor(maxScoreGEL)
    const colorPPE = getColor(maxScorePPE)

    return <Row justify="center" gutter={25}>
        <Col>
            <div className={"ant-tag ant-tag-" + colorGEL} style={{ margin: 0, borderWidth: "1px", borderStyle: "solid", padding: 15, textAlign: "center" }}>
                <div style={{ fontSize: 16, fontWeight: "500", marginBottom: 10 }}>Score GEL</div>
                <div style={{ fontSize: 20, fontWeight: "bold" }}>{maxScoreGEL}</div>
            </div>
        </Col>
        <Col>
            <div className={"ant-tag ant-tag-" + colorPPE} style={{ margin: 0, borderWidth: "1px", borderStyle: "solid", padding: 15, textAlign: "center" }}>
                <div style={{ fontSize: 16, fontWeight: "500", marginBottom: 10 }}>Score PPE</div>
                <div style={{ fontSize: 20, fontWeight: "bold" }}>{maxScorePPE}</div>
            </div>
        </Col>
    </Row>
}
