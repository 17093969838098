import React, { Component } from 'react';
import { Card, Form, Space, Row, Col, PageHeader, Input, Select, Popconfirm, DatePicker, Button } from 'antd';
import { Link } from 'react-router-dom';
import { ArrowRightOutlined } from "@ant-design/icons"
import moment from 'moment';

import UserContext from "../../Context/User"
import ListeNationalite from '../Data/ListeNationalite';
import hasEnoughRight, { ROLE } from "../../Utils/hasEnoughRight"

import 'moment/locale/fr';
moment.locale('fr');

class Home extends Component {
    static contextType = UserContext

    state = {
        typeclient: "PHYSIQUE",
        popConfirmState: true
    }

    searchForm = React.createRef()

    constructor(props) {
        super(props)

        // SI ON REVIENT DE LA LISTE DES RESULTATS
        // on met dans le state les éléments de la recherche
        var goback_state = props.location.state
        if (goback_state) {
            if (goback_state["datenaissance"]) goback_state["datenaissance"] = moment(goback_state["datenaissance"])
            this.state = { ...this.state, ...goback_state }
        }
    }

    componentDidMount(){
        this.doINeedPopConfirm()
    }

    onSearch = data => {
        if (data["datenaissance"]) data["datenaissance"] = data["datenaissance"].toISOString()

        this.props.history.push({
            pathname: '/recherche',
            state: data
        })
    }

    renderListeNationalite() {
        return ListeNationalite.map(nationalite => {
            return <Select.Option key={nationalite.id} value={"n-"+nationalite.id}>{nationalite.name}</Select.Option>
        })
    }

    doINeedPopConfirm = _ => {
        if(!!this.searchForm.current){
            const type = this.searchForm.current.getFieldValue("typeclient") === "PHYSIQUE"
            const state = Object.values(this.searchForm.current.getFieldsValue(["nom", "prenom", "datenaissance"])).map(e => !!e)
            const popConfirmState = type && state.includes(false)
            this.setState({ popConfirmState })
            return state
        }
    }

    render() {
        const { user } = this.context
        return (
            <PageHeader
                title="Liste des contrôles"
                ghost={false}>

                <Row gutter={15} justify="center">
                    <Col flex={"700px"} md={50} lg={15}>
                        <Card title="Faire une recherche" size="small">
                            <Form
                                ref={this.searchForm}
                                size="middle"
                                onFinish={this.onSearch}
                                onFieldsChange={this.doINeedPopConfirm}
                                initialValues={{
                                    ...this.state,
                                    liste: this.state.liste || ["datalake-ppe", "datalake-gel", "rnipp"],
                                    typeclient: this.state.typeclient || "PHYSIQUE"
                                }}
                                labelCol={{ lg: { span: 7 }, md: { span: 50 } }}>

                                <Form.Item label="Type de client" name="typeclient">
                                    <Select style={{ width: "100%" }} onSelect={typeclient => this.setState({ typeclient })} >
                                        <Select.Option value="PHYSIQUE">Personne physique</Select.Option>
                                        <Select.Option value="MORALE">Personne morale</Select.Option>
                                    </Select>
                                </Form.Item>

                                {this.state.typeclient === "PHYSIQUE" ?
                                    <>
                                        <Form.Item name="nom" label="Nom">
                                            <Input
                                                autoComplete="off"
                                                allowClear />
                                        </Form.Item>

                                        <Form.Item name="nomnaissance" label="Nom naissance">
                                            <Input
                                                autoComplete="off"
                                                allowClear />
                                        </Form.Item>

                                        <Form.Item name="prenom" label="Prenom">
                                            <Input
                                                autoComplete="off"
                                                allowClear />
                                        </Form.Item>

                                        <Form.Item name="datenaissance" label="Date de naissance">
                                            <DatePicker
                                                style={{ width: "100%" }}
                                                showToday={false}
                                                allowClear
                                                autoComplete="off"
                                                format={"DD/MM/YYYY"} />
                                        </Form.Item>

                                        <Form.Item name="nationalite" label="Nationalité">
                                            <Select
                                                showSearch
                                                allowClear
                                                placeholder="Sélectionner une nationalité"
                                                optionFilterProp="children"
                                                mode="multiple"
                                                filterOption={(input, option) => {
                                                    return option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                }}>
                                                {this.renderListeNationalite()}
                                            </Select>
                                        </Form.Item>
                                    </>
                                    :
                                    <>
                                        <Form.Item name="raisonsociale" label="Raison Sociale">
                                            <Input autoComplete="off" />
                                        </Form.Item>
                                    </>
                                }

                                <Form.Item
                                    name="liste"
                                    label="Rechercher dans"
                                    rules={[{ required: true, message: 'Veuillez choisir au moins une liste.' }]}>
                                    <Select
                                        showSearch
                                        allowClear
                                        optionFilterProp="children"
                                        mode="multiple"
                                        filterOption={(input, option) => {
                                            return option.children && option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }}>
                                        <Select.Option value="datalake-gel">Liste GEL</Select.Option>
                                        <Select.Option value="datalake-ppe">Liste PPE</Select.Option>
                                        <Select.Option value="rnipp">Liste RNIPP</Select.Option>
                                        <Select.Option value="client">Référentiel Client</Select.Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item name="valider" wrapperCol={{ lg: { offset: 7, span: 10 }, }}>

                                    {
                                        this.state.popConfirmState ?
                                            <Popconfirm
                                                okText="Faire la recherche"
                                                onConfirm={_ => this.searchForm?.current?.submit()}
                                                title={<>Vous n'avez pas rempli tous les champs requis :<ul style={{ margin: "5px 0" }}><li>Nom</li><li>Prenom</li><li>Date de naissance</li></ul>Le score des résultats peut être altéré.</>}>
                                                <Button type='primary' block>Rechercher</Button>
                                            </Popconfirm>
                                            :
                                            <Button type='primary' htmlType="submit" block>Rechercher</Button>
                                    }

                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>

                    <Col flex="250px">
                        <Card title="Lien rapide" size="small">
                            <Space direction="vertical" size="large" style={{ width: "100%" }}>
                                <Link to="/list/client"><Button block type="primary">Référentiel client <ArrowRightOutlined /></Button></Link>

                                {hasEnoughRight(user.role, ROLE.Auditeur) && <Link to="/list/controle-auditeur"><Button block type="primary">Contrôle auditeur<ArrowRightOutlined /></Button></Link>}

                            </Space>
                        </Card>
                    </Col>
                </Row>

            </PageHeader>
        )
    }
}

export default Home;
