import React from 'react';

import { Route, Switch } from 'react-router-dom';

import Layout from "./Layout"

import LogIn from "./LogIn/LogIn"
import Route404 from './Route404/Route404';
import ResetPassword from './ResetPassword/ResetPassword';
import ForgottenPassword from './ForgottenPassword/ForgottenPassword';

export default class Router extends React.Component {
    render() {
        return <Layout>
            <Switch>
                <Route path="/" exact component={LogIn} />
                <Route path="/mot-de-passe-oublie" component={ForgottenPassword} />
                <Route path="/reinitialiser-mot-de-passe/:reset_id" component={ResetPassword} />
                <Route exact path='/*' component={Route404} />
            </Switch>
        </Layout>
    }
}