import React, { Component } from 'react';
import { Link } from "react-router-dom"
import { PageHeader, Button, Table, Tag } from "antd"

import { getAllUsers } from "../../../API/User"

import UserContext from "../../../Context/User"
import Title from "../../../Utils/Title"

export default class ListUsers extends Component {
    static contextType = UserContext

    state = {
        loading: true,
        users: []
    }

    componentDidMount() {
        getAllUsers(this.context.user.token).then(data => {
            this.setState({
                loading: false,
                users: data.data
            })
        }).catch(err => {

        })
    }

    render() {
        return (
            <PageHeader
                title="Utilisateurs"
                extra={<Button onClick={_ => this.props.history.push("/settings/user/new")} type="primary">Ajouter</Button>}
                ghost={false}>

                <Table dataSource={this.state.users} loading={this.state.loading} rowKey="email">
                    <Table.Column title="Email" dataIndex="email" />
                    <Table.Column
                        title="Rôle"
                        dataIndex="role"
                        render={role => <Tag>{Title(role)}</Tag>}
                    />
                    <Table.Column
                        title="Action"
                        key="action"
                        align="right"
                        render={(_, record) => (
                            <Link to={"/settings/user/" + record.email}>Editer</Link>
                        )}
                    />
                </Table>

            </PageHeader>
        );
    }
}