import { BASE_URL } from "./Config"
import { makeGet, makeGetURL, makePost, makeDelete, makeRequest } from "./Util"

export function getAllUsers(token, all = false) {
    let params = { all }
    return makeRequest(makeGetURL(`${BASE_URL}/users`, params), makeGet(token))
}

export function createUser(data, token) {
    return makeRequest(`${BASE_URL}/users`, makePost(data, token))
}

export function getUser(email, token) {
    return makeRequest(`${BASE_URL}/users/${email}`, makeGet(token))
}

export function updateUser(email, data, token) {
    return makeRequest(`${BASE_URL}/users/${email}`, makePost(data, token))
}

export function deleteUser(email, token) {
    return makeRequest(`${BASE_URL}/users/${email}`, makeDelete(token))
}

export function getHistory(params, token) {
    return makeRequest(makeGetURL(`${BASE_URL}/users/history`, params), makeGet(token))
}

export function getHistoryAction(token) {
    return makeRequest(`${BASE_URL}/users/history/action`, makeGet(token))
}


export function changePassword(token, values) {
    return makeRequest(`${BASE_URL}/users/change-password`, makePost(values, token))
}