import React, { Component } from 'react';

import { Row, Col, Form, PageHeader, Modal, Input, Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';

import UserContext from "../../Context/User"

import { ForgottenPassword as ForgottenPassword_API } from "../../API/Authentification"

export default class ForgottenPassword extends Component {
    static contextType = UserContext

    state = {
        loading: false
    }

    onFinish = ({ email }) => {
        this.setState({ loading: true })

        ForgottenPassword_API(email)
            .then(d => {
                this.setState({ loading: false })

                console.log(d)

                Modal.success({
                    title: "Réinitialisation du mot de passe",
                    content: "Si ce compte existe, vous recevrez un lien pour réinitaliser votre mot de passe par mail.",
                    center: true
                })

            })
            .catch(err => {
                this.setState({ loading: false })

                Modal.error({
                    title: 'Réinitialisation du mot de passe',
                    content: err,
                    centered: true,
                    maskClosable: true
                })

            })
    }

    render() {
        return (
            <Row justify="center" align="top">
                <Col flex={2}></Col>
                <Col flex="700px">
                    <PageHeader
                        ghost={false}
                        onBack={this.props.history.goBack}
                        title="Mot de passe oublié">

                        <Form
                            size="large"
                            onFinish={this.onFinish}>

                            <Form.Item
                                name="email"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Veuillez entrer votre email !',
                                    },
                                    {
                                        type: "email",
                                        message: 'Email invalide',
                                    }
                                ]}>
                                <Input prefix={<UserOutlined />} placeholder="Email" />
                            </Form.Item>

                            <Form.Item>
                                <Button
                                    loading={this.state.loading}
                                    type="primary"
                                    block
                                    htmlType="submit">Réinitialiser mon mot de passe</Button>
                            </Form.Item>
                        </Form>
                    </PageHeader>
                </Col>
                <Col flex={2}></Col>
            </Row>
        )
    }
}