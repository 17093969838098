import React from 'react'
import ReactDOM from 'react-dom'

import { BrowserRouter as Router, Switch } from "react-router-dom"
import { ConfigProvider } from 'antd'

import { UserProvider, UserConsumer } from "./Context/User"

import ScrollToTop from "./ScrollToTop"

import RouterAuthentificated from "./Authentificated/Router"
import RouterNotAuthentificated from "./NotAuthentificated/Router"

import frFR from 'antd/es/locale/fr_FR'

import './index.less'

ReactDOM.render(
	<ConfigProvider locale={frFR}>
		<Router>
			<UserProvider>
				<ScrollToTop />
				<Switch>
					<UserConsumer>
						{data => data.user.connected === false ? <RouterNotAuthentificated /> : <RouterAuthentificated />}
					</UserConsumer>
				</Switch>
			</UserProvider>
		</Router>
	</ConfigProvider>,
	document.getElementById('root')
)

