import React from 'react'

import { Switch, Route, Redirect } from "react-router-dom"

import { Layout, Menu, Typography } from 'antd'
import { UserOutlined, SearchOutlined, DatabaseOutlined, SwapOutlined, AppstoreOutlined, TeamOutlined } from '@ant-design/icons'

import UserContext from "../../Context/User"
import hasEnoughRight, { ROLE } from "../../Utils/hasEnoughRight"

import Account from "./Account/Account"
import ListUsers from "./Users/ListUsers"
import EditUser from "./Users/EditUser"
import History from "./History/History"
import Repositories from "./Repositories/Repositories"
import Group from "./Groups/Group"
import Groups from "./Groups/Groups"
import ChangeGroup from "./Groups/ChangeGroup"

export default class Settings extends React.Component {
    static contextType = UserContext

    MENU = {
        "/settings": "1",
        "/settings/account": "1",
        "/settings/users": "2",
        "/settings/history": "3",
        "/settings/client": "4",
        "/settings/groups": "5",
        "/settings/change-group": "6",
    }

    state = {
        currentPage: this.MENU[this.props.location.pathname]
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) this.setState({ currentPage: this.MENU[this.props.location.pathname] })
    }

    render() {
        const { user } = this.context

        return <Layout>

            <Layout.Sider width={250} style={{ background: "transparent", }}>

                <Typography.Title level={4} style={{ background: "white", padding: 25, margin: 0 }}>Paramètres</Typography.Title>


                <Typography.Text strong style={{ background: "white", display: "block", padding: "5px 25px", margin: 0 }}>Général</Typography.Text>
                <Menu
                    mode="inline"
                    selectedKeys={this.state.currentPage}
                    onClick={({ item }) => {
                        this.props.history.push(item.props.to)
                    }}
                    style={{ height: 'auto', paddingBottom: 15, border: 0 }}>

                    <Menu.Item icon={<UserOutlined />} key="1" to="/settings/account">Mon compte</Menu.Item>

                    {hasEnoughRight(user.role, ROLE.Auditeur) && <Menu.Item icon={<SearchOutlined />} key="3" to="/settings/history">Historique des utilisateurs</Menu.Item>}
                </Menu>

                {hasEnoughRight(user.role, ROLE.Administrateur) &&
                    <>
                        <Typography.Text strong style={{ background: "white", display: "block", padding: "5px 25px", margin: 0 }}>Administration</Typography.Text>
                        <Menu
                            mode="inline"
                            selectedKeys={this.state.currentPage}
                            onClick={({ item }) => {
                                this.props.history.push(item.props.to)
                            }}
                            style={{ height: 'auto', paddingBottom: 25, border: 0 }}>

                            <Menu.Item icon={<TeamOutlined />} key="2" to="/settings/users">Utilisateurs</Menu.Item>

                            <Menu.Item icon={<DatabaseOutlined />} key="4" to="/settings/client">Référentiel client</Menu.Item>
                        </Menu>
                    </>
                }

                {hasEnoughRight(user.role, ROLE.Root) &&
                    <>
                        <Typography.Text strong style={{ background: "white", display: "block", padding: "5px 25px", margin: 0 }}>ROOT : {this.context.user.group}</Typography.Text>
                        <Menu
                            mode="inline"
                            selectedKeys={this.state.currentPage}
                            onClick={({ item }) => {
                                this.props.history.push(item.props.to)
                            }}
                            style={{ height: 'auto', paddingBottom: 25, border: 0 }}>

                            <Menu.Item icon={<AppstoreOutlined />} key="5" to="/settings/groups">Gestion des groupes</Menu.Item>
                            <Menu.Item icon={<SwapOutlined />} key="6" to="/settings/change-group">Changer de groupe</Menu.Item>
                        </Menu>
                    </>
                }

            </Layout.Sider>



            <Layout.Content style={{ padding: '0 24px', minHeight: 280 }}>
                <Switch>
                    <Route exact path="/settings/account" component={Account} />

                    {hasEnoughRight(user.role, ROLE.Auditeur) && <Route exact path="/settings/history" component={History} />}

                    {hasEnoughRight(user.role, ROLE.Administrateur) && <Route exact path="/settings/user/:id" component={EditUser} />}
                    {hasEnoughRight(user.role, ROLE.Administrateur) && <Route exact path="/settings/users" component={ListUsers} />}
                    {hasEnoughRight(user.role, ROLE.Administrateur) && <Route exact path="/settings/client" component={Repositories} />}

                    {hasEnoughRight(user.role, ROLE.Root) && <Route exact path="/settings/group/:id" component={Group} />}
                    {hasEnoughRight(user.role, ROLE.Root) && <Route exact path="/settings/groups" component={Groups} />}
                    {hasEnoughRight(user.role, ROLE.Root) && <Route exact path="/settings/change-group" component={ChangeGroup} />}


                    {this.props.location.pathname === "/settings" && <Redirect to="/settings/account" />}
                </Switch>
            </Layout.Content>


        </Layout>
    }
}