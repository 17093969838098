import React, { Component } from 'react'

import { Col, Row, Spin, Typography, Result, Modal, Divider, PageHeader, Button } from 'antd';
import { AlertOutlined, CheckCircleOutlined } from "@ant-design/icons"

import UserContext from "../../Context/User"

import { getPersonByIdFrom, changeMatchStatus } from '../../API/List';

import History from "./History"
import PersonInformation from "./PersonInformation"
import Matchs from "./Match"
import Comments from "./Comment"

import Title from "../../Utils/Title"

export default class Client extends Component {
    static contextType = UserContext

    state = {
        client_loaded: false,
        client_found: false,
        client: {},
        client_soft_id: {},
        match: [],
        history: [],
        controle_done: 0
    }

    componentDidMount() {
        const client_id = this.props.match.params.client_id
        const { token } = this.context.user

        // On récupère les données du client
        getPersonByIdFrom("client", client_id, token).then(data => {

            if (data.found) {

                let client = {}
                let client_soft_id = {}

                for(const d of Object.entries(data._source)){
                    if (/^ID/.test(d[0])) {
                        client_soft_id[d[0]] = d[1]
                    } else {
                        client[d[0]] = d[1]
                    }
                }

                this.setState({ client_loaded: true, client_found: true, client, match: client.COMPARAISON || [], client_soft_id }, this.isControleDone)

            } else {
                this.setState({ client_loaded: true, client_found: false })
            }

        }).catch(err => {
            Modal.error({
                title: "Erreur lors de la récupération du client",
                content: err,
                centered: true,
                maskClosable: true
            })
        })
    }

    isControleDone = _ => {
        this.setState({ controle_done: this.state.match.filter(e => e.REMOVED === undefined && e.APPROVED === undefined).length === 0 })
    }

    // Lors du changement détat d'une comparaison
    onChangeMatchStatus = (index, id, status) => {
        const { client_id } = this.props.match.params
        const { token, user } = this.context.user
        changeMatchStatus(client_id, index, id, status, token).then(d => {

            let match = this.state.match.find(e => e.INDEX_MATCH === index && e.ID_MATCH === id)

            match[status] = new Date()
            match[status + "_BY"] = user

            let others = this.state.match.filter(e => (e.INDEX_MATCH !== index || e.ID_MATCH !== id))

            this.setState({ match: [match, ...others] }, this.isControleDone)

        }).catch(err => {
            console.error(err)
            Modal.error({
                title: `Erreur lors de la ${status === "APPROVED" ? "validation" : "suppression"} d'une comparaison`,
                content: err,
                centered: true,
                maskClosable: true
            })
        })

    }

    render() {
        if (this.state.client_loaded) {
            return this.state.client_found ? (
                <PageHeader
                    title={`Client : ${this.state.client.NOM || this.state.client.RAISONSOCIALE} ${Title(this.state.client.PRENOM)}`}
                    onBack={this.props.history.goBack}
                    extra={<ControleTitle done={this.state.controle_done} />}
                    ghost={false}>

                    <Row gutter={50}>
                        <Col span={12}>

                            <Typography.Title level={4}>Information client</Typography.Title>
                            <PersonInformation person={this.state.client} bold />

                            <PersonInformation person={this.state.client_soft_id} bold marginTop />

                            <Divider />

                            <Typography.Title level={4}>Commentaire</Typography.Title>
                            <Comments client_id={this.props.match.params.client_id} user={this.context.user} />

                        </Col>

                        <Col span={12}>
                            <Typography.Title level={4}>Comparaison avec les listes de contrôle</Typography.Title>
                            <Matchs match_list={this.state.match} onChangeMatchStatus={this.onChangeMatchStatus} />

                            <Divider />

                            <Typography.Title level={4}>Historique</Typography.Title><br />
                            <History match_error={this.state.match_error} match={this.state.match} />

                        </Col>
                    </Row>
                </PageHeader>

            )

                : // Client non trouvé
                (
                    <Result
                        status="404"
                        title="404 - Client"
                        subTitle="Nous n'avons pas trouvé ce client."
                        extra={<Button onClick={_ => this.props.history.goBack()}>Retour</Button>}
                    />
                )
        } else {
            return <div style={{ flex: 1, lineHeight: "500px", textAlign: "center" }}>
                <Spin spinning={this.state.loading} tip="Chargement..." />
            </div>
        }
    }
}

const ControleTitle = ({ done }) => {
    if (done === 0) return ""
    if (done) {
        return <Typography.Title level={4} code style={{ color: "green" }} strong={true}><CheckCircleOutlined style={{ marginRight: 10 }} />Contrôle terminé</Typography.Title>
    } else {
        return <Typography.Title level={4} code strong={true}><AlertOutlined style={{ marginRight: 10 }} />Contrôle nécessaire</Typography.Title>
    }
}
