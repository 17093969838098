import { BASE_URL } from "./Config"
import { makeGet, makeGetURL, makePost, makeDelete, makeRequest } from "./Util"

export function getTokens(token) {
    return makeRequest(`${BASE_URL}/import/token`, makeGet(token))
}

export function getHistory(params, token) {
    return makeRequest(makeGetURL(`${BASE_URL}/import/history`, params), makeGet(token))
}

export function createToken(data, token) {
    return makeRequest(`${BASE_URL}/import/token`, makePost(data, token))
}

export function deleteToken(token, user_token) {
    return makeRequest(`${BASE_URL}/import/token/${token}`, makeDelete(user_token))
}