import React from 'react'

import { Empty, Timeline } from 'antd';

import moment from "moment"

const FORMAT_WHEN = "DD/MM/YYYY [à] HH[h]mm"

export default function History({ match }) {

    if (match.length > 0) {

        let history = []

        for (const i of match) {
            const type = i.INDEX_MATCH.replace("datalake-", "").toUpperCase()

            let text = `Comparaison avec la liste ${type} (Score: ${i.SCORE})`
            let color = type === "PPE" ? "orange" : type === "GEL" ? "red" : "pink"
            history.push({ date: moment(i.ADDED).valueOf(), text, color })

            if (i.REMOVED) {
                let text = `Comparaison ${type} refusée par : ${i.REMOVED_BY}`
                history.push({ date: moment(i.REMOVED).valueOf(), text, color: "grey" })
            }

            if (i.APPROVED) {
                let text = `Comparaison ${type} confirmée par : ${i.APPROVED_BY}`
                history.push({ date: moment(i.APPROVED).valueOf(), text, color: "green" })
            }

            if (i.CANCELLED) {
                let text = `Comparaison ${type} annulée par : ${i.CANCELLED_BY}`
                history.push({ date: moment(i.CANCELLED).valueOf(), text, color: "red" })
            }
        }

        history = history.sort((a, b) => b.date - a.date).map(d => ({ ...d, date: moment(d.date).format(FORMAT_WHEN) }))

        return <Timeline mode={"left"}>
            {
                history.map(hit => {
                    return <Timeline.Item key={hit.date + Math.random()} color={hit.color} label={hit.date}>{hit.text}</Timeline.Item>
                })
            }
        </Timeline>
    } else { // Si historique vide
        return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
    }

}
